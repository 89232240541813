.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 10px;
  left: 0;
  width: 100%;
  margin-top: 100px; /* Add the desired margin-top value here */
}

.swiper {
  margin-left: -20px;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: -18px;
  z-index: 1;
}
.swiper-pointer-events {
  touch-action: pan-y;
}

.banner-swiper-image {
  margin-right: 0px !important;
}

.hh-home-lat-join-when-no-job-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid #e1e1e1;
  max-width: 640px;
  width: 100%;
}
.c-left,
.c-right {
  flex: 1;
}

.no-job-found-img {
  width: 199.998px;
  height: 200px;
}
.hh-home-no-job-found-text {
  color: #000;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}
.hh-home-no-job-found-text-div {
  row-gap: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


@media (max-width: 1290px) {
  .c-left,
  .c-right {
    flex: 1; /* Distribute available space equally between .c-left and .c-right */
  }

  .c-left {
    max-width: 640px; /* Maximum width for .c-left */
  }

  .lady-img {
    max-width: 480px; /* Maximum width for the image */
    width: 100%;
    height: auto;
  }

  .c-right {
    max-width: 640px; /* Maximum width for .c-right */
  }
}
