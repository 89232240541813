.share-invite-friends-icons-div {
  display: flex;
  align-items: center;
  column-gap: 8px;
}
.copy-clipboard-invite-friends {
  cursor: pointer;
  /* background-color: #fff; */
}
.copy-clipboard-invite-friends-div{
    width: 40px;
    height: 40px;
    border-radius: 100px;
    background-color: #f0f0f3;
    display: flex;
    align-items: center;
    justify-content: center;
}
