/* .ch-cp-dropdown-box {
  width: 578px;
  width: auto;
  height: 50px;
  border: 1px solid #d7d7d7;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 10px 14px;
} */
/* .ch-cp-dropdown-box::placeholder {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 200px;
  line-height: 150%;
  color: #7b7b7b;
  background-color: #910707;
} */
.css-1jqq78o-placeholder {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  color: #7b7b7b;
}
.ch-cp-dropdown-box::placeholder {
  width: 100%;
  min-width: 537px;
}
.ch-cp-form-label {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  color: #7b7b7b;
}
.ch-cp-form-label-input {
  display: flex;
  height: 30px;
  column-gap: 10px;
  align-items: center;
  justify-content: center;
}
.ch-cp-dropdown-select {
  height: 30px;
  width: 350px;
  /* max-width: 550px; */
}
.ch-cp-form-input {
  height: 30px;
  padding: 5px;
}
.ch-cp-community-dropdown {
  display: flex;
  /* width: 580px; */
  /* height: 289px; */
  height: auto;
  background: #ffffff;
  border: 1px solid #a7a7a7;
  border-radius: 14px;
}
.ch-cp-community-icon {
  width: 64px;
  height: 64px;
}
.ch-cp-checkbox {
  width: 24px;
  height: 24px;
}
.ch-cp-dropdown-option {
  display: flex;
  align-items: center;
}
.ch-cp-dropdown-text-div {
  margin: 12px 51px 12px 16px;
}
.css-13cymwt-control,
.css-qbdosj-Input,
.css-ovqh0o-control {
  width: 578px;
  /* width: auto; */
  height: 50px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 8px !important;
}
#react-select-3-input {
  width: 550px;
  height: 40px;
}
.css-19bb58m::after {
  width: 550px;
  height: 40px;
}
.css-qbdosj-Input {
  width: 556.5px;
  height: 40px;
}
.ch-cp-dropdown-community-text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  color: #403d39;
}
.react-select__single-value {
  color: red; /* Change the color to your desired value */
}
.ch-cp-dropdown-community-tag {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: #7b7b7b;
}
.css-1nmdiq5-menu {
  max-width: 600px;
  /* width: auto; */
}
.ch-cp-dropdown-box {
  /* width: auto; */
  min-width: 600px !important;
  width: 100%;
}
@media screen and (max-width: 896px) {
  .css-13cymwt-control,
  .css-ovqh0o-control,
  .css-qbdosj-Input {
    width: 200px;
    color: #ffffff;
    border-radius: 8px;
  }
  .ch-cp-dropdown-select {
    width: 200px;
  }
  .ch-cp-community-icon {
    width: 12px;
    height: 12px;
  }
  .ch-cp-dropdown-text-div {
    margin: 8px;
  }
  .ch-cp-dropdown-box .ch-cp-dropdown-option {
    white-space: pre-wrap;
    /* overflow: auto; */
    /* text-overflow: ellipsis; */
  }
  .css-1nmdiq5-menu {
    width: auto;
  }
  /* .ch-cp-dropdown-box {
    width: auto;
  max-width: 200px;

  } */
  .ch-cp-dropdown-box {
    /* width: auto; */
    min-width: 200px !important;
    width: 100%;
  }
  .css-1jqq78o-placeholder {
    font-size: 14px;
    margin-left: 5px;
  }
  .ch-cp-dropdown-community-tag {
    font-size: 14px;
    width: 85px;
    word-wrap: break-word;
  }
  .ch-cp-dropdown-community-text {
    font-size: 16px;
  }
  .ch-cp-checkbox {
    width: 18px;
    height: 18px;
  }
  #react-select-3-input {
    width: 200px;
    height: 40px;
  }
  .css-19bb58m::after {
    width: 200px;
    height: 40px;
  }
  .ch-cp-dropdown-box::placeholder {
    width: 100%;
    min-width: 200px;
  }
  .custom-create-post-modal{
    /* top: 15%; */
    /* left: auto; */
    margin: 10% 3%;
  }
}
