.banner-container {
  background: linear-gradient(90deg,
      var(--color-background-darkblue) 0%,
      var(--color-brand-color) 100%);
  width: 100%;
  height: 100%;
  /* while using, keep the height in a fixed unit if the there is not content on the right side*/
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: -3;
}

.white-line-1 {
  border: 3px solid var(--color-text-white);
  border-radius: 27px;
  width: 78%;
  height: 95%;
  margin-right: 7px;
  z-index: -3;
}

.white-line-2 {
  border: 3px solid var(--color-text-white);
  border-radius: 27px;
  width: 104%;
  height: 69%;
  position: absolute;
  top: 14%;
  z-index: -3;
}

.outer-circle-1 {
  width: 542px;
  height: 542px;
  border: 16px solid var(--color-text-white);
  border-radius: 50%;
  z-index: -2;
  position: absolute;
  top: 66px;
  left: -32px;
}

.inner-circle {
  width: 500px;
  height: 500px;
  z-index: -2;
  background: var(--color-text-white);
  border-radius: 50%;
  position: absolute;
  left: 4.5px;
  top: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.outer-circle-2 {
  width: 507px;
  height: 507px;
  border: 3px solid var(--color-text-white);
  border-radius: 50%;
  z-index: -2;
  position: absolute;
  right: -10px;
  top: 409px;
}

.image-r-container {
  width: 475px;
  height: 475px;
  position: absolute;
  right: 14px;
  top: 14.5px;
  z-index: -1;
}

.image-r {
  width: 100%;
  height: 100%;
}

.r-header {
  font-size: 45px;
  font-weight: 600;
  color: var(--color-text-dark);
  font-family: var(--font-family-banner-header), sans-serif;
  width: 75%;
  align-self: center;
  margin-bottom: 19px;
  margin-left: 73px;
}

.r-description {
  width: 59%;
  font-family: var(--font-family-banner-description), sans-serif;
  font-weight: 400;
  align-self: center;
  font-size: 16px;
}

@media (min-width: 1125px) and (max-width: 1440px) {
  .outer-circle-2 {
    right: -10px;
    top: 432px;
    width: 480px;
    height: 480px;
  }

  .image-r-container {
    width: 445px;
    height: 445px;
  }
}

@media screen and (max-width: 480px) {
  .banner-container {
    width: 100%;
    height: 90vh;
  }

  .white-line-2 {
    width: 95%;
    height: 84%;
    top: 7%;
  }

  .outer-circle-1 {
    width: 286.15px;
    height: 276.87px;
    top: 56px;
    left: 20px;
    border: 9px solid var(--color-text-white);
  }

  .inner-circle {
    width: 265.22px;
    height: 253px;
    top: 2.9px;
    left: 1px;
  }

  .outer-circle-2 {
    width: 266.96px;
    height: 255.51px;
    right: 19px;
    top: 335px;
  }

  .image-r-container {
    width: 247.22px;
    height: 235.59px;
    right: 7px;
    top: 8px;
  }

  .r-header {
    font-size: 22px;
    margin-left: 20px;
    margin-bottom: 10px;
  }

  .r-description {
    font-size: 14px;
    margin-left: -18px;
  }
}

@media (min-width: 800px) and (max-width: 1200px) {
  .inner-circle {
    width: 354px;
    height: 354px;
    z-index: -2;
    background: var(--color-text-white);
    border-radius: 50%;
    position: absolute;
    left: 7.5px;
    top: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .outer-circle-2 {
    width: 383px;
    height: 383px;
    border: 3px solid var(--color-text-white);
    border-radius: 50%;
    z-index: -2;
    position: absolute;
    right: 7px;
    top: 540px;
  }

  .outer-circle-1 {
    width: 398px;
    height: 394px;
    border: 16px solid var(--color-text-white);
    border-radius: 50%;
    z-index: -2;
    position: absolute;
    top: 66px;
    left: -4px;
  }

  .image-r-container {
    width: 346px;
    height: 346px;
    position: absolute;
    right: 14px;
    top: 14.5px;
    z-index: -1;
  }

  .r-header {
    font-size: 36px;
  }
}


@media (min-width: 765px) and (max-width: 770px) {
  .inner-circle {
    width: 325px;
    height: 314px;
    z-index: -2;
    background: var(--color-text-white);
    border-radius: 50%;
    position: absolute;
    left: 7.5px;
    top: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .outer-circle-2 {
    width: 383px;
    height: 383px;
    border: 3px solid var(--color-text-white);
    border-radius: 50%;
    z-index: -2;
    position: absolute;
    right: 7px;
    top: 540px;
  }

  .outer-circle-1 {
    width: 371px;
    height: 358px;
    border: 16px solid var(--color-text-white);
    border-radius: 50%;
    z-index: -2;
    position: absolute;
    top: 98px;
    left: -4px;
  }

  .image-r-container {
    width: 346px;
    height: 346px;
    position: absolute;
    right: 14px;
    top: 14.5px;
    z-index: -1;
  }

  .r-header {
    font-size: 29px;
    width: 73%;
    margin-left: 43px;
  }

  .r-description {
    width: 30%;
  }

  .white-line-2 {
    border: 3px solid var(--color-text-white);
    border-radius: 27px;
    width: 104%;
    height: 76%;
    position: absolute;
    top: 7%;
    z-index: -3;
  }

  .backbutton-desktop {
    margin-left: -263px;
    margin-top: 50px;
  }
}