.ecc-emp-chat-list-div,
.ecc-indchat-div {
  border-radius: 20px;
  border: 1px solid var(--stroke-light, #d7d7d7);
  background: var(--white, #fff);
  box-shadow: 0px 2px 4px -2px rgba(0, 0, 0, 0.06),
    0px 4px 8px -2px rgba(0, 0, 0, 0.1);
  padding: 32px;
  height: 926px; /*have to remove*/
  margin-bottom: 40px;
  position: relative;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
}
.ecc-emp-chat-heading {
  color: var(--text-dark, #403d39);
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
}
.ecc-hr-line {
  width: 100%;
  /* color: #D7D7D7; */
  /* height: 1px; */
  margin: 0px;
}
.margin-class-chat-div{
  margin-bottom: 24px;
  /* padding: 0px 0px 24px 0px; */
}
.customer-profile-img {
  width: 48px;
  height: 48px;
  /* padding: 36px 0px 0px 36px; */
  /* justify-content: flex-end; */
  /* align-items: center; */
  border-radius: 200px;
}
.ecc-emp-chat-customer-name {
  color: var(--text-dark, #403d39);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}
.ecc-emp-chat-time {
  color: var(--text-light, #7b7b7b);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin-left: 8px;
}
.ecc-emp-chat-msg {
  color: var(--text-light, #7b7b7b);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.ecc-emp-single-chat-div {
  /* padding: 16px 0px; */
  display: flex;
  justify-content: space-between;
}
.ecc-emp-single-chat-div-list{
  display: flex;
  justify-content: space-between;
  padding: 16px 5px;
  border-radius: 5px;
  cursor: pointer;
  height: 92px;
}
.ecc-emp-single-chat-div-list:hover{
  background-color: #efebeb;
}
.ecc-emp-single-chat-div-list:active{
  background-color: #d7d7d7;
}
.emp-chat-list-cust-side-div{
  display: flex;
  flex-direction: column;
  /* row-gap: 16px; */
  /* margin-top: 16px; */
}
.ecc-emp-chat-profile-pic-div {
  margin: 0px 12px 12px 0px;
}
.ecc-emp-single-chat-details {
  row-gap: 12px;
}
.ecc-chat-icons {
  margin: 8px;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
}
.ecc-chat-heading-div {
  display: flex;
  justify-content: space-between;
}
.ecc-chat-icons-div {
  display: flex;
  column-gap: 8px;
}
.ecc-emp-single-chat-div-part {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}
.ecc-chat-active-status-icon-div, .active-icon-div-chat-section {
  margin-top: -12px;
  z-index: 1;
  position: absolute;
  margin-left: 36px;
  /* position: absolute; */
  /* bottom: 0; */
  /* right: 0; */
}
.active-icon-div-chat-section{
  bottom: 25px;
}
@media screen and (max-width: 896px) {
  .ecc-emp-chat-list-div {
    margin-top: 30px;
    padding: 24px;
  }
  .status-icon-online{
    width: 10px;
    height: 10px;
  }
  .ecc-emp-single-chat-div-list{
    height: auto;
  }
  .ecc-indchat-div{
    padding: 24px 16px;
  }
}
