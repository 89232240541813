/* .back-icon-container {
    margin-left: -240px;
    margin-top: 50px;
  } */
  .icon-image {
    width: 16px;
    height: 16px;
    margin-right:8px;
  }
  .back-icon-btn {
    background-color: var(--color-white);
    color: #8692a6;
    font-family: "Roboto";
    cursor: pointer;
  }
  @media screen and (max-width: 480px){
    .icon-image{
      margin-top: -1px;
    }
  }
