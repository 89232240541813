.form-container{
  width:100%;
}

.form-heading {
  font-size: 24px;
  font-weight: 700;
  color: var(--color-text-dark);
  margin-top: 10px;
  margin-bottom: 8px;
}
.form-description {
  font-size: 17px;
  font-weight: 400;
  color: #8692a6;
  margin-top: 10px;
  margin-bottom: 32px;
}
.fields-1 {
  width: 100%;
  display: flex;
  gap: 18px;
  margin-bottom: 20px;
  justify-content:space-between ;
}
.fields-2 {
  width: 100%;
  margin-bottom: 20px;
}

.field-fn,
.field-ln,
.field-email,
.field-ph,
.field-country,
.field-state,
.field-city,
.field-zipcode {
  width: 100%;
}
.terms-policy {
  color: var(--color-text-light);
  font-weight: 700;
  font-size: 14.85px;
  margin-top: 20px;
  line-height: 1.5;
}
.link-terms {
  color: var(--color-text-dark);
  text-decoration: none;
}

.error-msg {
  color: red;
}
@media screen and (max-width:480px){
  #ep-container{
    flex-direction: column;
  }
}
