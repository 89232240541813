.ahjpl-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 32px;
  flex: 1 0 0;
  font-family: Roboto;
  font-style: normal;
  color: var(--text-dark, #403d39);
  margin-bottom: 40px;
}
.ahjpl-ind-div {
  display: flex;
  align-items: center;
  align-self: stretch;
  column-gap: 40px;
  padding: 32px;
  border-radius: 20px;
  border: 1px solid var(--stroke-light, #d7d7d7);
  background: var(--white, #fff);
  box-shadow: 0px 2px 4px -2px rgba(0, 0, 0, 0.06),
    0px 4px 8px -2px rgba(0, 0, 0, 0.1);
  font-family: Roboto;
  font-style: normal;
  color: var(--text-dark, #403d39);
  max-width: 971px;
  width: 100%;
}
.ahjpl-pagination-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.ahjpl-pagination-prev-div {
  display: flex;
  padding: 8px 20px;
  justify-content: center;
  align-items: center;
  column-gap: 12px;
  border-radius: 4px;
  border: 1px solid var(--brand-color, #04aff0);
}
.ahjplid-part-a {
  display: flex;
  height: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 16px;
}
.ahjplid-part-b {
  display: flex;
  height: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 24px;
  max-width: 687px;
  width: 100%;
}
.ahjplid-pa-img-div,
.ajplid-pa-img-ind-div {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  max-width: 180px;
  width: 100%;
}
.ahjplid-pa-img,
.ajplid-pa-img-ind {
  border-radius: 200px;
  width: 180px;
  height: 180px;
}
.ahjplid-pa-name-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--text-dark, #403d39);
  font-family: Roboto;
  font-style: normal;
  text-align: center;
  max-width: 180px;
  width: 100%;
}
.ahjplid-pa-name-div-ahid {
  font-size: 24px;
  font-weight: 700;
  line-height: 140%; /* 33.6px */
  margin-bottom: 4px;
  max-width: 180px;
  width: 100%;
  overflow-wrap: break-word;
}
.ahjplid-pa-name-div-location {
  font-size: 14px;
  font-weight: 500;
  line-height: 150%; /* 21px */
  overflow-wrap: break-word;
  /* white-space: pre-wrap; */
  max-width: 180px;
  width: 100%;
}
.ahjplid-pb-s1 {
  display: flex;
  flex-direction: row;
  column-gap: 24px;
  width: 100%;
  justify-content: space-between;
}
.ahjplid-pb-s1-position-div {
  display: flex;
  flex-direction: row;
  column-gap: 24px;
  max-width: 580px;
  width: 100%;
}
.ahjplid-pb-s1-position-label-div {
  display: flex;
  align-items: center;
}
.ahjplid-pb-s2-position-label-div {
  display: flex;
}
.ahjplid-pb-s1-position-label {
  font-size: 16px;
  font-weight: 700;
  line-height: 150%;
}
.ahjplid-pb-s1-position-values-div {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
}
.ahjplid-pb-s1-position-value-div {
  border-radius: 20px;
  background: var(--chip-blue, #eff5ff);
  display: flex;
  padding: 4px 8px;
  align-items: flex-start;
  flex-direction: row;
  column-gap: 24px;
  align-items: center;
  justify-content: center;
}
.ahjplid-pb-s1-position-value {
  font-size: 14px;
  font-weight: 400;
  line-height: 150%; /* 21px */
}
.ahjplid-pb-s2 {
  width: 100%;
  display: flex;
  column-gap: 24px;
}
.ahjplid-pb-s2-text {
  font-size: 18px;
  font-weight: 400;
  line-height: 150%; /* 27px */
  max-width: 500px;
  width: 100%;
  overflow-wrap: break-word;
}
.ahjplid-pb-s3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}
.ahjplid-pb-s3-row {
  width: 100%;
  column-gap: 32px;
  display: flex;
}
.ahjplid-pb-s3-r-cell {
  max-width: 327px;
  width: 100%;
  display: flex;
  font-size: 16px;
  row-gap: 8px;
  flex-direction: column;
}
.ahjplid-pb-s3-r-label {
  font-weight: 700;
  line-height: 150%; /* 24px */
}
.ahjplid-pb-s3-r-value {
  font-weight: 400;
  line-height: 150%; /* 24px */
}
.ahjplid-pb-s3-r-value-salary {
  color: var(--brand-color, #04aff0);
  font-weight: 700;
}
/* .ahjplid-pb-s1-button-div , .ajplid-pb-s1-button-div{
  width: 100%;
  max-width: 125px;
} */
.ajplid-pb-s1-button-div{
  width: 125px;
}
.ahjplid-pb-s1-button {
  color: var(--text-white, #fff);
  font-family: Roboto;
  font-style: normal;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%; /* 24px */
  display: flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: var(--brand-color, #04aff0);
  box-shadow: 0px 2px 4px -2px rgba(0, 0, 0, 0.06),
    0px 4px 8px -2px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 125px;
}
.ahjpl-pagination-prev-text {
  color: var(--brand-color, #04aff0);
  font-size: 16px;
  font-weight: 400;
  line-height: 150%; /* 24px */
}
.ahjpl-pagination-prev-icon-div {
  max-width: 24px;
  width: 100%;
  max-height: 24px;
}
.ahpl-pagination-prev-button-div {
  border-radius: 4px;
  border: 1px solid var(--brand-color, #04aff0);
  display: flex;
  padding: 8px 20px;
  justify-content: center;
  align-items: center;
  gap: 12px;
}
.ahpl-pagination-prev-button {
  color: var(--brand-color, #04aff0);

  /* Text/Regular/Normal */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  display: flex;
  align-items: center;
  gap: 12px;
}
.ahpl-pagination-page-button-div {
  display: flex;
  width: 40px;
  height: 40px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;
  border-radius: 8px;
  margin: 0px;
}
.ahpl-pagination-page-buttons-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.ahpl-page-button {
  padding: 5px 10px;
  margin: 2px;
  border: none;
  cursor: pointer;
  /* background-color: #f0f0f0; */
  border-radius: 8px;
  transition: background-color 0.3s, color 0.3s;
}

.ahpl-page-button.active {
  background-color: #04aff0;
  color: white;
  border-radius: 8px;
}
.no-posts-message {
  font-weight: 500;
  justify-content: center;
  align-items: center;
  color: var(--text-dark, #403d39);
  font-family: Roboto;
  font-style: normal;
  text-align: center;
}
.no-posts-message-div {
  display: flex;
  width: 100%;
  margin: auto;
  text-align: center;
  justify-content: center;
}
@media screen and (max-width: 896px) {
  .ahjplid-pa-img-div {
    max-width: 55px;
    height: 55px;
    flex-shrink: 0;
  }
  .ahjplid-pa-img {
    width: 55px;
    height: 55px;
    flex-shrink: 0;
  }
  .ajplid-pa-img-ind-div {
    max-width: 98px;
    height: 98px;
    align-items: center;
    margin: auto;
  }
  .ajplid-pa-img-ind {
    width: 98px;
    height: 98px;
  }
  .ahjpl-ind-div {
    padding: 16px;
    flex-direction: column;
    row-gap: 16px;
    align-items: flex-start;
  }
  .ahjplid-part-a {
    flex-direction: row;
    column-gap: 16px;
  }
  .ahjplid-pa-name-div {
    text-align: left;
    column-gap: 16px;
  }
  .ahjplid-pb-s1 {
    flex-direction: column;
    row-gap: 24px;
  }
  .ahpl-pagination-prev-button-div {
    padding: 5px;
    column-gap: 5px;
  }
  .ahpl-pagination-page-button-div {
    width: 30px;
    padding: 4px;
  }
  .ahjplid-pb-s3-row {
    flex-direction: column;
    row-gap: 16px;
  }
  .ahindfv-p1-details{
    
  }
}
