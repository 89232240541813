.modal-container-cso {
    width: 400px;
    height: 200px;
    border-radius: 12px;
    /* top:30vh; */
    /* right:0vh; */
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .header-eso{
      border:none;
      padding-bottom:0px;
      padding-top: 25px;
      padding-left: 0px;
      
  }
  .title-eso{
      font-size:18px;
      font-weight:600;
      padding-left: 22px;
  }
  .body-eso{
      font-size: 14px;
      padding-top:10px;
      padding-left: 22px;
      color:var(--color-text-light);
  }
 
 