.otp-verification-container {
  width: 100%;
  height: 100%;
  display: flex;
}
.backbutton-otp-component {
  margin-left:-430px;
  margin-top: 50px;
}
.otp-verification-field-component{
  width:100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.field-content-component {
  width: 500px;
  margin-left: 15px;
}
.field-content {
  width: 60%;
  margin-left: 15px;
}
.field-heading {
  font-size: 24px;
  font-weight: 700;
  color: var(--color-text-dark);
  margin-top: 50px;
  margin-bottom: 8px;
}
.field-description {
  font-size: 17px;
  font-weight: 400;
  color: #8692a6;
  margin-top: 12px;
  margin-bottom: 32px;
  line-height: 1.5;
}
.button-otpv{
  margin-bottom: 450px;
}
.otp-footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -200px;
  margin-bottom: 250px;
}
.o-footer-content {
  font-size: 14px;
  font-weight: 700;
  color: var(--color-text-extra-light);
  margin-top: -168px;
  margin-bottom: 132px;
}
.resend-button {
  background-color: var(--color-white);
  color: var(--color-brand-color);
  font-weight: 700;
  font-size: 14px;
}
.otp-time{
  display: flex;
  justify-content: center;
  color: var(--color-brand-color);
  font-size: 2rem;
}

@media screen and (max-width: 480px) {
  .backbutton-otp-component {
    margin-left: -294px;
    margin-top: 5px;
  }
  .otp-verification-field-component {
    width: 95%;
   
  }
  .otp-inputcontainer{
    width:95%;
    margin-bottom: -5px;
  }
  .field-content-component {
    width: 95%;
    margin-left: 10px;
  }
  .button-otp{
    width:100%;
  }
  .otp-footer {
    margin-top:-208px;
    margin-bottom: 0px;
  }
  .o-footer-content {
    margin-top:0px;
    margin-bottom: 0px;
  }
  .button-otpv{
    margin-bottom: 50px;
    margin-top:108px;
    margin-left: 10px;
  }
}
