.profile-topbar {
  width: 100%;
  height: 56px;
  background: var(--color-white);
  border-bottom: 1px solid #e7e0ec;
  position: fixed;
  z-index: 999;
}

.top-bar-icons {
  cursor: pointer;
  padding-bottom: 12px;
}
.top-bar-icons-mob{
  padding: 0px;
  padding-top: 10px;

}
.active-top-bar-icons {
  cursor: pointer;
  /* border-bottom: 2px solid #04aff0; */
  padding-bottom: 12px;
}
.active-top-bar-icons-mob{
  padding: 0px !important;
}
.active-top-bar-icons-mob:hover{
  padding: 0px !important;
}
.active-top-bar-icons-mob::after{
  padding: 0px !important;
}
.top-bar-hr {
  height: 2px !important;
  color: #04aff0;
  width: 150px !important;
  opacity: unset !important;
  margin: 0px 0;
}
.top-bar-hr-mob{
  width: 60px !important;

}
.pr-top-bar-img-div {
  width: 150px;
}
.pr-top-bar-img-div-mob{
  width: 70px;
}
.hh-top-bar-profile-icon {
  width: 40px;
  height: 40px;
  border-radius: 1000px;
}

