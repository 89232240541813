.cp-cancel-btns-div {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

.cp-save-btn {
  width: 107px;
  height: 43px;
  font-weight: 400;
  font-size: 20px;
}

.cp-cancel-btn {
  color: #403d39;
  width: 107px;
  height: 43px;
  background-color: #fff;
  border: 1px solid #d7d7d7;
  font-weight: 400;
  font-size: 20px;
}

.cp-update-text {
  color: #7b7b7b;
  font-size: 16px;
}
.cp-form-div {
  width: 100%;
}
.cp-form-label-input {
  display: flex;
  align-items: center;
}
.cp-form-label {
  color: #7b7b7b;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  width: 40%;
}

.cp-form-input {
  height: 50px;
  background: #ffffff;
  border: 1px solid #d7d7d7;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  width: 100%;
  padding: 10px;
}

.error-msg{
  color: red;
}

.namefields{
  width: 50%;
}
.profileeditinput{
  /* width: 80%; */
  width: 100%;
  max-width: 512px;
  display: flex;
  flex-direction: column;
  gap:10px;
}
.profileeditinput-cust-req-sal{
  /* flex-direction: column; */
  gap:10px;
  display: flex;
  width: 100%;
  max-width: 512px;
}
.emp-pro-edit-sal{
  gap:10px;
  display: flex;
  width: 100%;
  max-width: 512px;
}
.profileedit-employe-value{
  width: 100%;
  max-width: 512px;
  display: flex;

}
.profileeditinputs{
  width: 80%;
  display: flex;
  flex-direction: row;
  gap:10px;
}

.profileeditinput div {
  width: auto;
  height: auto;
  min-height: 30px;
  border-radius: 12px;
 }
 
 
.cp-form-texteditor-subtag{
  color: var(--text-light, #7B7B7B);

/* Text/Regular/Normal */
font-family: Roboto;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 24px */
}


@media only screen and (max-width: 600px) {
  .cp-form-label-input {
    /* align-items: flex-start; */
    flex-direction: column;
    gap: 5px;
  }
  .profileeditinput{
    width: 100%;
    gap: 10px;
  }
  .cp-form-label{
    width: 100%;
  }
}