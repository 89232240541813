.Reset-container {
  width: 100%;
  display: flex;
}

.ResetPassword-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
  margin-bottom: 450px;
}

.form-resetpassword {
  width: 60%;
  
}

.heading-resetpassword {
  font-size: 24px;
  font-weight: 700;
  color: var(--color-text-dark);
  margin-bottom: 10px;
}
.paragraph-resetpassword {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #8692a6;
  padding-bottom: 30px;
}
.password-container {
  margin-top: 30px;
}
.confirmpassword {
  margin-top: 20px;
}
.backbutton-reset {
  margin-bottom: 50px;
 
}
.button-ResetPassword {
  padding-top: 30px;
}

@media screen and (max-width: 480px) {
    .form-resetpassword{
        width: 85%;
    }
    .backbutton-reset{
        margin-bottom: -10px;
    }
    .ResetPassword-container {
        width:100%;
    }
    .backbutton-reset{
        margin-bottom: 50px;
        margin-top: -40px;
        margin-right: 10px;
    }
    
}
