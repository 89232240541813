.emp-label-div {
  line-height: 2.5;
}
.emp-exp {
  padding-right: 280px;
}

.emp-experience {
  align-items: center;
  padding: 4px 12px;

  width: 59px;
  height: 29px;

 

  background: #eff5ff;
  border-radius: 16px;
  display: inline;
  margin-right: 10px;
}
.emp-label-div {
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
}

.top-card-bar {
  display: flex;
  align-items: center;

  align-self: stretch;
}
.add-more-btn-expereience {
  margin-bottom: 20px;
}
@media screen and (max-width: 480px) {
  .emp-label-div {
    display: flex;

    flex-direction: column;

    white-space: nowrap;
  }
  .add-more-btn-expereience {
    margin-bottom: 64px;
  }
}
