.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-cp {
  width: 100%;
  background-color: #ffffff;
}
/* .modal-content-cp {
  width: 500px;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  position: fixed;
  top: 17vh;
} */
.ch-changepro-pic {
  margin-left: 10px;
}
/* .ch-changepro-pic-text {
  width: 90%;
  margin-top: 6px;
  margin-left: 15px;
} */
/* .ch-changepro-pic-text1 {
  font-size: 18px;
  width: 358px;
} */

.close-button-cp {
  width: 24px;
  height: 24px;
  margin-top: -21px;
  cursor: pointer;
}
.line-1-modal {
  width: 200px;
  height: 200px;
  border-top: 2px solid black;
}
.modal-body-cp {
  /* width:442px; */
  height: 180px;
  border: 1.5px dashed var(--color-brand-color);
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 440px;
  width: 100%;
  padding: 16px 24px;
}
/* .ch-profilechange-image-dropzone {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
} */

.text-dropzone-1 {
  text-decoration: none;
  color: var(--color-brand-color);
  font-size: 15px;
}
.text-dropzone-2 {
  font-size: 13px;
  text-align: center;
}

.modal-button-1 {
  max-width: 214px;
  height: 44px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  background-color: var(--color-white);
  width: 100%;
}
.footer-modal-cp{
  width: 100%;
  height: auto;
  max-width: 214px;

}
.modal-button-2 {
  max-width: 214px;
  height: 44px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  border: 1px solid #04aff0;
  background-color: var(--color-brand-color);
  color: var(--color-white);
  width: 100%;
}
/* .image-preview-container-main {
  width: 442px;
  height: 310px;
  border: 1.5px dashed var(--color-brand-color);
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
} */
.image-previews {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.close-b-ip {
  position: absolute;
  top: 7px;
  right: 7px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
@media screen and (max-width: 896px) {
  /* .modal-content-cp {
    width: 373px;
    height: 546px;
  } */
  .modal-body-cp {
    /* width:442px; */
    height: 180px;
    border: 1.5px dashed var(--color-brand-color);
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 240px;
    width: 100%;
    padding: 10px 14px;
  }
  .modal-button-1, .modal-button-2, .footer-modal-cp {
    max-width: 100px;

    
}
}