.cce-chat-list-div {
  overflow-y: auto;
}
.ecc-search-employees-chat-list {
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid var(--Stroke-Light, #d7d7d7);
  background: var(--White, #fff);

  /* xxsmall */
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  color: var(--text-light, #7b7b7b);

  /* Text/Regular/Normal */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  margin: 26px 0px 16px;
}
