/* CustomerPostDeletePostComponent.css */

.cu-post-three-dots-menu-icon {
  cursor: pointer;
  position: relative;
}
.cp-tdots-menu-list-icon {
  cursor: pointer;
}

/* Styles for the dropdown menu */
.cp-dropdown-threedots-menu {
  position: absolute;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 237px;
  align-items: flex-start;
  width: auto;
  flex-direction: column;
  border-radius: 12px;
  background: var(--White, #fff);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06),
    0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  /* row-gap: 8px; */
  flex-direction: column;
}

.cp-tdots-menu-button-div1:hover {
  transition: background-color 0.3s ease;
  background-color: #efeded;
  border-radius: 12px;

}
.cp-tdots-menu-button-div2:hover {
  transition: background-color 0.3s ease;
  background-color: #efeded;
  border-radius: 12px;

}

.cp-tdots-menu-button-div1:hover .cp-tdots-menu-button-edit {
  transition: background-color 0.3s ease;
  background-color: #efeded;
  border-radius: 12px;

}
.cp-tdots-menu-button-div2:hover .cp-tdots-menu-button-delete {
  transition: background-color 0.3s ease;
  background-color: #efeded;
  border-radius: 12px;

}
.cp-tdots-menu-button-div,
.cp-tdots-menu-button-div1,
.cp-tdots-menu-button-div2 {
  display: flex;
  height: 39px;
  padding: 8px;
  align-items: flex-start;
  column-gap: 8px;
  align-self: stretch;
  cursor: pointer;
  border-radius: 12px;

}
