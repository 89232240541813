.ahindfv-div,
.ajindfv-div {
  display: flex;
  column-gap: 32px;
  margin-bottom: 40px;
  border-radius: 20px;
  border: 1px solid var(--stroke-light, #d7d7d7);
  background: var(--white, #fff);

  /* small */
  box-shadow: 0px 2px 4px -2px rgba(0, 0, 0, 0.06),
    0px 4px 8px -2px rgba(0, 0, 0, 0.1);
  padding: 0px 40px;
}
.ajindfv-div {
  flex-direction: column;
  /* row-gap: 32px; */
  padding: 32px;
}
.ahindfv-p1-name-div {
  display: flex;
  align-items: center;
  column-gap: 16px;
}
.ahindfv-side-bar-div {
  display: flex;
  padding: 32px 0px;
  flex-direction: column;
  /* justify-content: center; */
  align-items: flex-start;
  gap: 16px;
}
.ahindfv-main-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  /* gap: 32px; */
  flex: 1 0 0;
}
.ahindfv-part1-div {
  display: flex;
  padding: 32px 32px 0px 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}
.ahindfv-part5-div,
.ahindfv-part6-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  /* padding: 32px; */
  padding: 32px 17px 0px 32px;
}
.ahindfv-part6-div {
  padding: 32px 17px 32px 32px;
}
.ahindfv-p1-name {
  color: var(--text-dark, #403d39);
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 33.6px */
  margin: 0px;
}
.ahindfv-p1-details-div,
.ahindfv-p5-details-div {
  border-radius: 9px;
  /* background: var(--divider, #eaecf0); */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 16px;
  border-top: #eaecf0 solid 2px;
  width: 100%;
  max-width: 1084px;
  padding-top: 12px;
}
.ahindfv-p5-details {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.ahindfv-p5-details-div {
  border: none;
}
.ahindfv-p1-details,
.ahindfv-p2-details,
.ahindfv-p3-details {
  flex-direction: row;
  display: flex;
  width: 100%;
}
.ahindfv-p2-details,
.ahindfv-p3-details {
  flex-direction: column;
  display: flex;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}
.ahindfv-p1-detail-label {
  display: flex;
  max-width: 169px;
  width: 100%;
  height: 32px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
}
.ahindfv-p5-detail-label {
  width: 100%;
  max-width: 300px;
  display: flex;
}
.ahindfv-p5-detail-label-text {
  width: 100%;
  max-width: 300px;
  color: var(--text-light, #7b7b7b);

  /* Text/Large/Normal */
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 30px */
}
.ahindfv-p1-detail-label-text,
.ahindfv-p2-detail-label-text,
.ahindfv-p5-detail-label-text {
  color: var(--text-light, #7b7b7b);
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 30px */
}
.ahindfv-p2-detail-label-text {
  color: var(--text-dark, #403d39);
  font-weight: 700;
  line-height: 140%; /* 28px */
}
.ahindfv-p5-detail-value-div {
  width: 100%;
  max-width: 600px;
  display: flex;
  align-items: center;
}
.ahindfv-p5-detail-value {
  color: var(--text-dark, #403d39);

  /* Text/Large/Normal */
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 30px */
}
.ahindfv-p1-detail-value-div,
.ahindfv-p1-detail-position-value-div,
.ahindfv-p4-detail-value-div {
  display: flex;
  height: 32px;
  flex-direction: column;
  justify-content: center;
  flex: 1 0 0;
  max-width: 442px;
}
.ahindfv-p4-detail-value-div {
  max-width: 732px;
}
.ahjplid-part-a-sidebar {
  align-items: normal;
}
.ahindfv-p1-detail-position-value-div {
  display: flex;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background: var(--chip-blue, #eff5ff);
  width: auto !important;
}
.ahindfv-p1-detail-value,
.ahindfv-p5-detail-value {
  color: var(--text-dark, #403d39);
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 30px */
}
.ahindfv-part2-div,
.ahindfv-part3-div,
.ahindfv-part4-div {
  display: flex;
  /* padding: 32px; */
  padding: 32px 17px 0px 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}
.ahindfv-p5-label {
  color: var(--text-dark, #403d39);
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 28px */
  margin: 0px;
}
.ahindfv-p6-value-img {
  width: 50px;
  height: 50px;
  cursor: pointer;
}
.ahindfv-p6-values-div {
  display: flex;
  align-items: flex-start;
  gap: 19px;
}
.ahindfv-p7-back-button-div {
  display: flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: var(--brand-color, #04aff0);
  /* small */
  box-shadow: 0px 2px 4px -2px rgba(0, 0, 0, 0.06),
    0px 4px 8px -2px rgba(0, 0, 0, 0.1);
}
.ahindfv-p7-back-button {
  color: var(--text-white, #fff);
  /* Text/Regular/Normal */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  background: transparent;
}
.ahindfv-part7-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px 32px;
  width: 100%;
}
.ahindfv-p7-print-msg-buttons-div {
  display: flex;
  flex-direction: row;
  column-gap: 12px;
  align-items: center;
}
.ahindfv-p7-print-button {
  color: var(--brand-color, #04aff0);

  /* Heading/Regualr/H6 */
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.ahindfv-p7-print-button-div {
  display: flex;
  padding: 10px 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 12px;
  border: 1px solid var(--brand-color, #04aff0);
  background: var(--white, #fff);

  /* xsmall */
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06),
    0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
.ahindfv-p7-msg-button {
  color: var(--white, #fff);
  /* Heading/Regualr/H6 */
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: transparent;
}
.ahindfv-p7-msg-button-div {
  border-radius: 12px;
  border: 1px solid var(--brand-color, #04aff0);
  background: var(--brand-color, #04aff0);
  /* xsmall */
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06),
    0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  padding: 10px 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
.ahindfv-p7-back-button-div-top {
  display: flex;
  /* padding: 40px 80px; */
  padding: 0px 0px 40px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}
.ch-cjp-details-div{
  display: flex;
  align-items: center;
}

@media screen and (max-width: 896px) {
  .ahindfv-div {
    flex-direction: column;
    padding: 0px 15px;
  }
  .ahindfv-side-bar-div {
    padding: 10px 0px;
  }
  .ahindfv-part1-div {
    display: flex;
    padding: 16px 0px 0px 0px;
  }
  .ahjplid-part-a-sidebar {
    align-items: center;
  }
  .ahindfv-part2-div,
  .ahindfv-part3-div,
  .ahindfv-part4-div,
  .ahindfv-part5-div {
    padding: 0px;
  }
  .ahindfv-part6-div {
    padding: 0px 0px 20px 0px;
    row-gap: 12px;
  }
  /* .ahindfv-p6-value-div{
    width: 30px;
    height: 30px;
  } */
  .ahindfv-p6-value-img {
    width: 30px;
    height: 30px;
  }
  .ahindfv-p7-back-button-div-top {
    padding-top: 35px;
  }
  .ahindfv-p6-values-div {
    column-gap: 10px;
  }
  .ajindfv-div {
    padding: 16px;
  }
  .ahindfv-p1-name-div {
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
    margin: auto;
  }
  .ahindfv-p1-details {
    /* column-gap: 10px; */
    flex-direction: column;
  }

}
