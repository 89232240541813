.button-r{
    width:100%;
    height:49px;
    background: var(--color-brand-color);
    border-radius: 6px;
    cursor: pointer;
    margin-top: 32px;
    margin-bottom: 32px;
    color:var(--color-white);
    font-size: 14px;
    font-weight: 700;
}
.button-r:disabled {
    cursor:not-allowed;
    background-color: #ccc;
    color:#888;
}