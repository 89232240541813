.ecc-indchat-div-part {
  column-gap: 16px;
  display: flex;
  align-items: center;
}
.ecc-indchat-div-heading-div {
  display: flex;
  justify-content: space-between;
  margin: 0px 0px 24px 0px;
}
.ecc-hr-line-margin-class {
  /* margin: 24px 0px; */
  color: #161616;

}
.ecc-Indchat-details {
  justify-content: space-between;
  display: flex;
  align-items: center;
}
.ecc-indchat-msg-receive-div {
  padding: 10px 14px;
  border-radius: 0px 12px 12px 12px;
  background: var(--light-grey, #f4f4f4);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06),
    0px 1px 3px 0px rgba(0, 0, 0, 0.1);
}
.ecc-indchat-recieved-msg-div {
  display: flex;
  /* column-gap: 12px; */
  /* margin-right: 80px; */
  margin-bottom: 16px;
  row-gap: 16px;
  flex-direction: column;
  max-height: 700px;
  overflow-y: auto;
}

.ecc-indchat-recieved-msg-div-cus-side {
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  column-gap: 12px;
}
.ecc-indchat-profile-pic-div {
  position: relative;
}

.ecc-indchat-receiver-msg-div-part {
  display: flex;
  flex-direction: column;
  row-gap: 6px;
  max-width: 428px;
  /* width: auto; */
  /* margin-right: 80px; */
}
.ecc-indchat-msg-send-div {
  padding: 10px 14px;
  border-radius: 12px 0px 12px 12px;
  background: var(--brand-color, #04aff0);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
    0px 1px 3px 0px rgba(16, 24, 40, 0.1);
}
.ecc-indchat-sender-msg-div-part {
  margin-right: 10px;
  margin-left: 80px;
  display: flex;
  justify-content: flex-end;
}
.ecc-indchat-sender-msg {
  color: var(--white, #fff);
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 27px */
}
/* .ecc-type-msg-editor{
position: absolute;
position: absolute;
bottom: 0;
width: 100%;
padding: 10px 0px ;
display: flex;
align-items: center;
margin-top: auto;
max-width: 468px;

} */
.ecc-type-msg-editor {
  position: relative;
  padding: 10px;
  display: flex;
  bottom: 0;
  margin-top: auto;

  align-items: center;
}

.ecc-type-msg-editor-div {
  display: flex;
  flex-direction: row;
}
.ecc-type-msg-editor-box {
  padding: 10px 14px;
  margin-bottom: 0px;
  border-radius: 8px;
  border: 1px solid var(--gray-300, #d0d5dd);
  background: var(--white, #fff);
  position: fixed;

  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.ecc-indChat-editor-send-icon-div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  cursor: pointer;
  margin-left: 10px;
}
.ecc-indChat-editor-send-icon-image {
  width: 44px;
  height: 44px;
}
.ecc-hr-line2 {
  margin-bottom: 32px;
}
.ecc-type-msg-editor-text-div {
  border-radius: 8px;
  border: 1px solid var(--gray-300, #d0d5dd);
  background: var(--white, #fff);
  /* padding: 10px 14px; */
  padding: 10px;
  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  /* flex-shrink: 1; */
  flex: 1;
  height: 44px;
  display: flex;
  max-width: 504px;
}
.ecc-type-msg-editor-text-div textarea {
  width: 100%;
  height: 100%;
  resize: none;
  border: none;
  outline: none;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  overflow-y: hidden;
}
@media screen and (max-width: 896px) {
  .ecc-indchat-sender-msg-div-part {
    margin-right: 5px;
    margin-left: 20px;
  }
}
