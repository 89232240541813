.Employee-psb-card{
    width: 277px;
  height: 288px;
  background: #ffffff;
  border: 1px solid #d7d7d7;
  box-shadow: 0px 4px 8px -2px rgba(0, 0, 0, 0.1),
    0px 2px 4px -2px rgba(0, 0, 0, 0.06);
  border-radius: 20px;

}

.Employee-psb-text {
  width: 70px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  background: #04aff0;
  border-radius: 20px;
  font-weight: 500;
  font-size: 12px;
}
.Employee-psb-text-div {
  display: flex;
  justify-content: flex-end;
  padding-top: 12px;
}
.Employee-psb-profile-img {
  width: 195px;
  height: 195px;
  border: 3px solid #ffffff;
  box-shadow: 0px 4.7619px 9.52381px -2.38095px rgba(0, 0, 0, 0.1),
    0px 2.38095px 4.7619px -2.38095px rgba(0, 0, 0, 0.06); 
  border-radius: 165.476px;
}
.Employee-psb-img-div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Employee-psb-name {
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #403d39;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Employee-psb-profile-img {
  z-index: 1;
}
.Employee-psb-camera-img {
  z-index: 2;
  padding-top: 95px;
  position: absolute;
  padding-left: 185px;
  cursor: pointer;
}

.Employee-psb-btn:hover {
  background: #04aff0;
  color: #ffffff;
}

.Employee-psb-about-div {
  width: 277px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Employee-psb-about-div p {
  color: #7b7b7b;
  font-size: 16px;
  cursor: pointer;
}

.Employee-psb-terms {
  display: flex;
  justify-content: center;
  width: 277px;
  color: #7b7b7b;
  font-size: 16px;
  cursor: pointer;
}
.Employee-psb-btn {
  display: flex;
  align-items: center;
  width: 277px;
  height: 44px;
  background: white;
  border: 1px solid #d7d7d7;
  box-shadow: 0px 4px 8px -2px rgba(0, 0, 0, 0.1),
    0px 2px 4px -2px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  font-size: 20px;
  line-height: 23px;
  color: black;
  padding-left: 55px;
  cursor: pointer;
}
/* .Employee-psb-btn-active {
  display: flex;
  align-items: center;
  width: 277px;
  height: 44px;
  background: #04aff0;
  color: #ffffff;
  border: 1px solid #d7d7d7;
  box-shadow: 0px 4px 8px -2px rgba(0, 0, 0, 0.1),
    0px 2px 4px -2px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  font-size: 20px;
  line-height: 23px;
  padding-left: 55px;
  cursor: pointer;
} */
.psb-term-ico{
  margin-top: 5px;
  margin-left: 5px;
}

@media (max-width: 767px) {
  .profile-layout-padding-class {
    padding: 0.5rem;
    padding-top: 0.5rem !important;
    margin-bottom: 54px;
  }
}