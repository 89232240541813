.psb-request-box {
  width: 277px;
  height: 133px;
  background: #ffffff;
  border: 1px solid #d7d7d7;
  box-shadow: 0px 4px 8px -2px rgba(0, 0, 0, 0.1),
    0px 2px 4px -2px rgba(0, 0, 0, 0.06);
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}
.psb-request-box-button {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #04aff0;
  width: 245px;
  height: 39px;
  border: 1px solid #04aff0;
  border-radius: 27px;
}
.psb-request-box-text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  color: #7b7b7b;
}
