.emp-qualification{
    align-items: center;
    padding: 4px 12px;
  
    width: 59px;
    height: 29px;
  
    /* Chip Blue */
  
    background: #eff5ff;
    border-radius: 16px;
    display: inline;
    margin-right: 10px;
   
  }
  .emp-qualific-card1 {
    background: #ffffff;
    height: auto;
    width: 100%;
    border: 1px solid #d7d7d7;
    box-shadow: 0px 4px 8px -2px rgba(0, 0, 0, 0.1),
      0px 2px 4px -2px rgba(0, 0, 0, 0.06);
    border-radius: 20px;
    padding: 20px;
    margin-bottom: 80px;
    line-height: 2.5;
  }
  
  @media screen and (max-width: 480px) {
    .emp-label-div {
      display: flex;
  
      flex-direction: column;
  
      white-space: nowrap;
    }
  
  }
  