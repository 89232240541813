@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

:root {
  --color-brand-color: #04aff0;
  --color-light-grey: #f4f4f4;
  --color-white: #ffffff;
  --color-black: #000000;
  --color-dashboard-background: #f7fafb;
  --color-divider: #eaecf0;
  --color-text-dark: #403d39;
  --color-text-light: #7b7b7b;
  --color-text-extra-light: #bdbdbd;
  --color-text-white: #ffffff;
  --color-border-light: #d7d7d7;
  --color-success: #22c55e;
  --color-warning: #eab308;
  --color-error: #ef4444;
  --color-neutral: #6b7280;
  --color-label-blue: #eff5ff;
  --color-footer-blue: #0250c5;
  --color-footer-pink: #d43f8d;
  --color-background-darkblue: #0057b0;
  --color-inputbox: #a2a2a6;

  --box-shadow-xxlarge: 0px 32px 64px -12px #000000;
  --box-shadow-xlarge: 0px 24px 48px -12px #000000;
  --box-shadow-large: 0px 20px 24px -4px #000000, 0px 8px 8px -4px #000000;
  --box-shadow-medium: 0px 12px 16px -4px #000000, 0px 4px 6px -2px #000000;
  --box-shadow-small: 0px 4px 8px -2px #000000, 0px 2px 4px -2px #000000;
  --box-shadow-xsmall: 0px 1px 3px #000000, 0px 1px 2px #000000;
  --box-shadow-xxsmall: 0px 1px 2px #000000;

  --font-family-banner-header: "Barlow Condensed";
  --font-family-banner-description: "Montserrat";

  --toastify-toast-width: auto;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  text-decoration: none;
}

body {
  font-family: "Roboto";
  font-style: normal;
}

h1 {
  font-size: 48px;
}

h2 {
  font-size: 40px;
}

h3 {
  font-size: 32px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 20px;
}

h6 {
  font-size: 18px;
}

p {
  /* font-size: 16px; */
  margin-bottom: 0px;
}

button {
  cursor: pointer;
  background-color: white;
}
.error-symbol {
  color: red;
}
.Toastify__toast-theme--colored.Toastify__toast--info {
  background: #04aff0;
}
select {
  font-size: 14px;
}
