@media screen and (max-width: 480px) {
    .emp-label-div-desktop {
      display: flex;
  
      flex-direction: column;
margin-bottom: 16px;
    }
 
  .emp-account-container{
    margin-bottom: 50px;
  }
  .cs-profile-main-acc{
    margin-bottom: 100px;
  }
  }
  