.ajpl-ind-div {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  border: 1px solid var(--stroke-light, #d7d7d7);
  background: var(--white, #fff);
  box-shadow: 0px 2px 4px -2px rgba(0, 0, 0, 0.06),
    0px 4px 8px -2px rgba(0, 0, 0, 0.1);
  font-family: Roboto;
  font-style: normal;
  color: var(--text-dark, #403d39);
  max-width: 971px;
  width: 100%;
  padding: 32px;
  row-gap: 32px;
}
.ajplid-pa-img-div {
  display: flex;
  align-items: center;
}
.ajplid-pa-img {
  border-radius: 200px;
  width: 40px;
  height: 40px;
}
.ajplist-part-a {
  display: flex;
  height: auto;
  justify-content: center;
  align-items: center;
  column-gap: 16px;
}
.ajplid-pa-name-div {
  display: flex;
  align-items: center;
}
.ajplid-pa-loc-div {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--text-dark, #403d39);
  font-family: Roboto;
  font-style: normal;
  text-align: center;
  width: 100%;
}
.ajplid-pa-loc-div {
  flex-direction: row;
}
.ajplist-pa-customer-details {
  display: flex;
  column-gap: 16px;
  /* width: 100%; */
  align-items: center;
  justify-content: center;
}
.ajplist-part1 {
  justify-content: space-between;
  display: flex;
  align-items: center;
}
.ajplid-pa-location-div {
  display: flex;
  border-radius: 20px;
  background: var(--chip-blue, #eff5ff);
  padding: 4px 8px;
}
.ajplid-pa-location {
  color: var(--text-dark, #403d39);

  /* Text/Small/Semi Bold */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 21px */
}
.ajplid-pa-name-div-ahid {
  color: var(--text-dark, #403d39);
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  /* line-height: 140%; 33.6px */
  margin: 0px;
}
.ajplist-part2 {
  display: flex;
  /* align-items: center; */
  column-gap: 40px;
  width: 100%;
}

.ajplist-part21 {
  display: flex;
  row-gap: 24px;
  flex-direction: column;
  max-width: 408px;
  width: 100%;
}
.ajplist-part22 {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 464px;
  align-items: flex-start;
  gap: 32px;
}
.ajplid-pb-row {
  display: flex;
  column-gap: 32px;
  width: 100%;
  max-width: 464px;
}
.ajplid-pb-cell {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  max-width: 216px;
  width: 100%;
}
@media screen and (max-width: 896px) {
  .ajplist-part1 {
    flex-direction: column;
    row-gap: 24px;
    align-items: normal;
  }
  .ajplist-part2 {
    flex-direction: column;
    row-gap: 24px;
  }
  .ajpl-ind-div {
    padding: 16px;
  }
.ajplist-pa-customer-details {
  flex-direction: column;
  align-items: normal;
  row-gap: 12px;
}
}
