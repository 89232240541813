.cr-form {
  padding: 32px;
  width: auto;
  height: auto;
  background: #ffffff;
  border: 1px solid #d7d7d7;
  box-shadow: 0px 4px 8px -2px rgba(0, 0, 0, 0.1),
    0px 2px 4px -2px rgba(0, 0, 0, 0.06);
  border-radius: 20px;
  margin-bottom: 40px;
}
.cr-form-heading-tagline {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #7b7b7b;
}
.cr-form-heading {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;
  color: #403d39;
  margin-bottom: 4px;
}
.cr-form-hr-line {
  height: 1px;
  background: #eaecf0;
}
.cust-req-form-sal-hiphen {
  display: flex;
  align-items: center;
}
.cp-form-creq-multi-select-dropdown {
  max-width: 512px;
  width: 100%;
}
.profileeditinput-cu-req-text-editor {
  max-width: 512px;
  width: 100%;
}
/* .proedit-cu-req-dropdown{
  max-width: 512px;
  width: 100%;
} */
.cu-req-cancel-btn {
  border-radius: 12px;
  border: 1px solid var(--Stroke-Light, #d7d7d7);
  background: var(--White, #fff);

  /* xsmall */
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06),
    0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  padding: 10px 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: var(--text-dark, #403d39);

  /* Heading/Regualr/H6 */
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
select {
  -webkit-appearance: initial;
  -moz-appearance: initial;
  appearance: initial;
}
.select-box-country{
  -webkit-appearance: initial;
  -moz-appearance: initial;
  appearance: initial;
}
.cu-req-select-drop-down-down-arrow, .country-down-arrow{
  width: 20px;
  height: 20px;
  color: #7B7B7B;
  position: absolute;
  margin-left: -30px;
  margin-top: 15px;

}
.country-down-arrow{
  width: 22px;
  height: 45px;
  margin-top: 0px ;
}
@media only screen and (max-width: 896px) {
  .profileeditinput-cu-req-text-editor {
    max-width: 312px;
  }
  .cr-form {
    padding: 16px;
  }
}
