.psb-btn-active-static  {
    display: flex;
    align-items: center;
    width: 277px;
    height: 42px;
    background: white;
    /* border: 1px solid #d7d7d7; */
    /* box-shadow: 0px 4px 8px -2px rgba(0, 0, 0, 0.1), 0px 2px 4px -2px rgba(0, 0, 0, 0.06); */
    border-radius: 12px;
    font-size: 20px;
    line-height: 16px;
    color: black;
    padding-left: 88px;
    cursor: pointer;
    font-weight: 600;
  /* Transition for smooth background color change */
  transition: background-color 0.3s;
}

.static-mob-button :hover {
    background-color: #04aff0;
     /* Change background color to blue when hovered */
}
.static-mob-button{
    padding: 18px;
    display: flex;
    width: 278px;
    padding: 20px;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    font-weight: 600;

}

.psb-terms-static{
    display: flex;
    justify-content: center;
    width: 277px;
    color: #7b7b7b;
    font-size: 16px;
    cursor: pointer;
    margin-top: 66px;
}

.Static-Employee-Mobile-card{
    width: 306px;
    height: 822px;
    background: #ffffff;
    border: 1px solid #d7d7d7;
    box-shadow: 0px 4px 8px -2px rgba(0, 0, 0, 0.1), 0px 2px 4px -2px rgba(0, 0, 0, 0.06);
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 2;
}