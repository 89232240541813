.button-box {
  width: 200px;
  height: 40px;
  position: relative;
  border-radius: 30px;
  background: var(--color-light-grey);
}
.button-r-toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 31px;
  padding-top: 11px;
}

.toggle-btn {
  cursor: pointer;
  background: transparent;
  border: 0;
  outline: none;
  position: relative;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
}
.toggle-btn-activated {
  color: var(--color-white);
}
#btn {
  left: 0;
  top: 0;
  position: absolute;
  width: 101px;
  height: 100%;
  background: var(--color-brand-color);
  border-radius: 30px;
  transition: 0.5s;
}

#c-button-r {
  right: -2px;
  top:-2px;
}
#e-button-r {
  left: 0;
  top:-2px;
}


