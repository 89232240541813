.modal-container-cso {
  max-width: 400px;
  width: 100%;
  height: 200px;
  border-radius: 12px;
  /* top:30vh;
    right:0vh; */
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.header-cso {
  border: none;
  padding-bottom: 0px;
  padding-top: 25px;
  padding-left: 0px;
}
.title-cso {
  font-size: 18px;
  font-weight: 600;
  padding-left: 22px;
}
.body-cso {
  font-size: 14px;
  padding-top: 10px;
  padding-left: 22px;
  color: var(--color-text-light);
}
.footer-cso {
  border: none;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding-top: 9px;
  background-color: #ffffff;
  border-radius: 12px;
}
.close-cso-div,
.signout-cso-div {
  width: 100%;
  max-width: 170px;
}
.close-cso {
  max-width: 170px;
  width: 100%;
  height: 44px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  background-color: var(--color-white);
  font-weight: 600;
}
.signout-cso {
  max-width: 170px;
  width: 100%;
  height: 44px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  border: 1px solid #04aff0;
  background-color: var(--color-brand-color);
  color: var(--color-white);
}
@media screen and (max-width: 896px) {
  .modal-container-cso {
    max-width: 300px;
    height: 250px;
  }
  .footer-cso {
    flex-direction: row;
  }
  .close-cso {
    max-width: 100px;
  }
  .signout-cso {
    max-width: 100px;
  }

  .close-cso-div,
  .signout-cso-div {
    width: 100%;
    max-width: 100px;
  }
}
