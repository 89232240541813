.profile-footer {
  height: 112px;
  width: 100%;
  background: linear-gradient(90deg, #0250c5 0%, #d43f8d 100%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* position: fixed; */
  /* bottom: 0; */

}

.footer-text {
  color: #ffffff;
  margin-bottom: 0px;
  font-size: 14px;
}

.footer-logo-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}
.footer-text-hh {
  color: #ffffff;
  margin-bottom: 0px;
}
