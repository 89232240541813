.ch-created-post-box {
  width: auto;
  height: auto;
  background: #ffffff;
  border: 1px solid #d7d7d7;
  box-shadow: 0px 4px 8px -2px rgba(0, 0, 0, 0.1),
    0px 2px 4px -2px rgba(0, 0, 0, 0.06);
  border-radius: 20px;
  padding: 32px;
  align-items: center;
  margin-bottom: 32px;
  overflow-y: auto;
}
/* Add the following CSS styles in your CSS file or style section */

.formatting-buttons {
  display: flex;
  margin-left: 8px; /* Add spacing between buttons */
  padding: 3px;

  padding-top: 30px;
}

.formatting-button {
  display: flex;
  align-items: center; /* Center the icon vertically */
  justify-content: center; /* Center the icon horizontally */
  padding: 8px 12px;
  background-color: #f0f0f0;
  border: none;
  cursor: pointer;
  font-weight: bold;
}
.load-more-comments-btn-div {
  border-radius: 6px;
  border: 1px solid #e0e0e1;
  background: #f1f2f3;
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  cursor: pointer;
}
.load-more-comments-btn {
  color: var(--text-dark, #403d39);
  /* Text/Small/Semi Bold */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 21px */
  background: #f1f2f3;

}
.comment-by-employee-button {
  margin-top: -41px;
  margin-right: 11px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  width: 80px;
  height: 32px;
  background: #04aff0;
  box-shadow: 0px 4px 8px -2px rgba(0, 0, 0, 0.1),
    0px 2px 4px -2px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  color: #ffffff;
  float: right;
}
.comment-by-employee-button:active {
  background: #014662;
}
.comment-by-employee-button:hover {
  background: #6abada;
}

.formatting-button:not(:last-child) {
  margin-right: 2px;
}

.add_comment_ip_b {
  width: 100%;
  height: 200px;
  border-radius: 8px;
  border: 1px solid #0089ff;
  background: #fff;
  box-shadow: 0px 4px 8px 0px rgba(10, 58, 100, 0.15);
}
.add_reply_ip_b {
  width: 100%;
  height: 200px;
  border-radius: 8px;
  border: 1px solid #0089ff;
  background: #fff;
  box-shadow: 0px 4px 8px 0px rgba(10, 58, 100, 0.15);
}
.comment_img_div {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  padding: 11px 14px;
}
.ch-job-description {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: #403d39;
  word-wrap: break-word;
}
.ch-cp-customer-details {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ch-job-description-div {
  margin: 32px 0px 0px;
}
.ch-cjp-hr-line {
  border: 2px solid #eaecf0;
  margin: 16px 0px;
}
.ch-cjp-profile-img {
  width: 40px;
  height: 40px;
  border-radius: 48px;
  margin-right: 15.98px;
}
.ch-cjp-cust-name {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 140%;
  color: #403d39;

  margin-right: 10px;
}
.ch-cjp-details,
.ch-cjp-details-button {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: #7b7b7b;
}
.ch-cjp-details-button {
  cursor: pointer;
}
.ch-cjp-category-icon,
.ch-cjp-location-icon {
  margin: 0px 10px 0px 26px;
}
.ch-cjp-dot-icon {
  margin-right: 8px;
}
.ch-cjp-comment-icon {
  margin-right: 11px;
  cursor: pointer;
}
.ch-cjp-replies-icon {
  margin: 0px 10px 0px 50px;
  /* cursor: pointer; */
}
.ch-created-post-box-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cp-comment-box {
  height: auto;
  border-top: 2px solid #eaecf0;
  margin-top: 16px;
}
.cp-comment-box-heading {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 140%;
  color: #403d39;
  z-index: 1;
  margin-top: 40px;
  margin-bottom: 40px;
}
.cp-comment-box-emp-name {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  color: #403d39;
  margin-left: 10px;
}
.cp-comment-box-comment {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: #7b7b7b;
  margin-top: -2.5px;
  margin-bottom: 21px;
  margin-left: 59px;
}
.cp-comment-box-emp-com-div {
  display: flex;
  column-gap: 8px;
  flex-direction: row;
  align-items: center;
}
.cp-comment-box-commented-time {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: #7b7b7b;
  padding-top: 1px;
}
.cp-comment-box-customer {
  border: 1px solid #0089ff;
  box-shadow: 0px 4px 8px rgba(10, 58, 100, 0.15);
  border-radius: 8px;
  height: 242px;
  padding: 20px;
  margin-bottom: 40px;
}
.cp-comment-box-reply-button {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  width: 71px;
  height: 32px;
  background: #04aff0;
  box-shadow: 0px 4px 8px -2px rgba(0, 0, 0, 0.1),
    0px 2px 4px -2px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  color: #ffffff;
  float: right;
}
.reply-to-employee-button {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  width: 71px;
  height: 32px;
  background: #04aff0;
  box-shadow: 0px 4px 8px -2px rgba(0, 0, 0, 0.1),
    0px 2px 4px -2px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  color: #ffffff;
  float: right;
}
.CCPB-replyText {
  margin-left: 51px;
  margin-top: -7px;
}
.reply-to-comment-by-button {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  width: 71px;
  height: 32px;
  background: #04aff0;
  box-shadow: 0px 4px 8px -2px rgba(0, 0, 0, 0.1),
    0px 2px 4px -2px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  color: #ffffff;
  float: right;
  margin-top: -44px;
  margin-right: 2%;
}

.CCPB-replyBox {
  margin-bottom: 21px;
}

.timecss {
  color: var(--text-light, #7b7b7b);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  margin-left: 6px;
}
.reply-to-reply-by-employee-button {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  width: 71px;
  height: 32px;
  background: #04aff0;
  box-shadow: 0px 4px 8px -2px rgba(0, 0, 0, 0.1),
    0px 2px 4px -2px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  color: #ffffff;
  float: right;
  margin-top: -62px;
}

.reply-to-comment-by-employee-button {
  margin-top: -54px;
  color: var(--brand-color, #04aff0);
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  float: right;
}

.cp-comment-box-load-more-button,
.cp-comment-box-load-more-button-for-posts {
  max-width: 880px;
  height: 41px;
  background: #f1f2f3;
  border: 1px solid #e0e0e1;
  border-radius: 6px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: #403d39;
}
.cp-comment-box-load-more-button {
  width: 100%;
  margin-top: 20px;
}
.cp-comment-box-load-more-button-for-posts {
  color: var(--text-white, #fff);

  font-size: 16px;
  font-family: Roboto;
  font-weight: 500;
  line-height: 150%;
  border-radius: 8px;
  background: var(--brand-color, #04aff0);
  box-shadow: 0px 2px 4px -2px rgba(0, 0, 0, 0.06),
    0px 4px 8px -2px rgba(0, 0, 0, 0.1);
  padding: 4px 16px;
  width: 117px;
  height: 48px;
}
.cp-comment-box-load-more-button-for-posts-div {
  display: flex;
  text-align: center;
  margin: 20px 0px 50px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.load-more-post-button-img {
  margin-top: -10px;
  cursor: pointer;
}
.ch-job-posted-img {
  margin: 15px auto;
  align-items: center;
  display: flex;
  justify-content: center;
  max-width: 700px;
  max-height: 500px;
}
.ch-cp-comment-profile-img {
  border-radius: 40px;
  width: 40px;
  height: 40px;
}
.CCPB-replySection {
  margin-left: 60px;

  background-color: #f2f2f2;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 32px;
}
.CCPB-replySubmitBtn {
  margin-top: -50px;
}
.css-hlgwow {
  padding: 2px 18px !important;
}

@media screen and (max-width: 896px) {
  .ch-created-post-box {
    padding: 16px;
    margin-bottom: 16px;
    margin-right: 12px;
    margin-left: 12px;
  }
  .ch-create-post-button-box {
    width: 100%;
    height: 56px;
    background: #ffffff;
    border: 1px solid #d7d7d7;
    box-shadow: 0px 4px 8px -2px rgba(0, 0, 0, 0.1),
      0px 2px 4px -2px rgba(0, 0, 0, 0.06);
    border-radius: 45px;
    padding: 8px;
    column-gap: 16px;
  }
  .ch-cjp-cust-name {
    margin-left: 0px;
    font-size: 18px;
  }
  .ch-cjp-dot-icon {
    margin-right: 5px;
  }
  .ch-cjp-details,
  .ch-cjp-details-button {
    font-size: 14px;
    margin-right: 0px;
  }
  .ch-cjp-category-icon {
    margin: 0px 8px 0px 12px;
    width: 21px;
    height: 21px;
  }
  .ch-cjp-location-icon {
    margin: 0px 9.75px 0px 12px;
    width: 17.5px;
    height: 17.5px;
  }
  .ch-cp-customer-details {
    flex-direction: column;
  }
  .ch-job-description-div {
    margin: 20px 0px 0px;
  }
  .ch-job-description {
    font-size: 16px;
  }
  .ch-cjp-replies-icon {
    margin: 0px 10px 0px 35px;
    width: 20px;
    height: 16px;
  }
  .ch-cjp-comment-icon {
    width: 18px;
    height: 18px;
  }
  .cp-comment-box-load-more-button,
  cp-comment-box-load-more-button-for-posts {
    width: 270px;
  }
  .cp-comment-box-customer {
    border: 1px solid #0089ff;
    box-shadow: 0px 4px 8px rgba(10, 58, 100, 0.15);
    border-radius: 8px;

    padding: 10px;
    margin-bottom: 20px;
  }
  .ch-job-posted-img {
    max-width: 250px;
    max-height: 200px;
  }
  .ch-cjp-profile-img {
    margin-right: 10px;
  }
  .CCPB-replySection {
    margin-left: -12px;
  }
}
