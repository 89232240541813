.ahlh-div {
  color: var(--text-dark, #403d39);
  font-family: Roboto;
  font-style: normal;
  line-height: 150%; /* 27px */
  width: 100%;
  height: auto;
  margin-bottom: 32px;
}
.ahlh-text {
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  height: auto;
}
.ahlh-heading {
  font-size: 48px;
  font-weight: 700;
  line-height: 120%; /* 57.6px */
  margin-top: 16px;
  margin-bottom: 24[x];
}
.ahlh-sub-description {
  font-size: 18px;
  font-weight: 400;
}
.ahlh-filter-mobile-view {
  display: none;
}
.ahlh-filter-button-div {
  display: flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 16px;
  border: 1px solid var(--Brand-Color, #04aff0);
  background: #fff;
}
.ahlh-filter-sort-buttons-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 5px;
}
@media screen and (max-width: 896px) {
  .ahlh-heading {
    line-height: 140%; /* 33.6px */
    font-size: 24px;
  }
  .ahlh-sub-description {
    font-size: 16px;
  }
  .ahlh-filter-mobile-view {
    display: block;
  }

  .ahlh-div {
    padding: 5px;
  }
  .ahsb-terms-cond-div {
    display: none;
  }
}
