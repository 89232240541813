.input-box-cpassword {
    width: 100%;
    height: 43px;
    border: 1px solid var(--color-inputbox);
    border-radius: 4px;
    margin-top: 6px;
    margin-bottom: 3px;
    padding: 12px;
  }
  .label-registration {
    font-weight: 400;
    font-size: 14px;
    color: var(--color-text-dark);
  }
  .input-box-cpassword::placeholder {
    color: var(--color-text-extra-light);
    font-size: 12px;
  }
  