.psb-ntfn-box {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  width: 277px;
  height: 124px;
  border: 1px solid #d7d7d7;
  box-shadow: 0px 4px 8px -2px rgba(0, 0, 0, 0.1),
    0px 2px 4px -2px rgba(0, 0, 0, 0.06);
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  margin: 32px 0px 32px;
  background-color: #fff;
}
.psb-ntfn-box-text-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 245px;

}
.psb-ntfn-box-text {
  color: #403d39;
  line-height: 150%;
  font-weight: 400;
  font-family: "Roboto";
  font-style: normal;
  font-size: 20px;
  /* margin: 0px 0px 0px 16px; */
}
.psb-ntfn-box-number {
  color: #04aff0;
  line-height: 150%;
  font-weight: 400;
  font-family: "Roboto";
  font-style: normal;
  font-size: 20px;
  margin-right: 8px;
}
.psb-ntfn-box-text-container {
  margin-left: 8px;
}
