.emp-noti-container {
  display: flex;
  width: 100%;
  padding: 32px;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  margin-bottom: 30px;
  border: 1px solid var(--stroke-light, #d7d7d7);
  border-radius: 20px;
  background: var(--white, #fff);
  min-height: 80vh;

  /* small */
  box-shadow: 0px 2px 4px -2px rgba(0, 0, 0, 0.06),
    0px 4px 8px -2px rgba(0, 0, 0, 0.1);
}
.delete-all-notifi-div {
  padding: 16px 32px;
  width: 100%;
}
.delete-all-noti-icon-div {
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
  /* gap: 8px; */
  border-radius: 24px;
  background: var(--Chip-Blue, #eff5ff);
  width: 56px;
  height: 56px;
  margin: 0px 0px 0px auto;
  cursor: pointer;
}
.delete-all-noti-icon-div:hover{
  background: var(--Chip-Blue, #eef0f3);
}
.delete-all-noti-icon-div:active{
  background: var(--Chip-Blue, #9bb5df);
}
.notifi-hr-line {
  width: 100%;
  background: #3b4049;
}
.notifi-hr-line-div {
  width: 100%;
  margin: 16px 0px 0px 0px ;
}
.emp-noti-hr-line {
  background: #3b4049;
  height: 1px;
  opacity: 0.8;
  margin: 0px;
}

@media screen and (max-width: 480px) {
  .emp-noti-container {
    margin-top: 30px;
  }
}
