
/**Add the responsive css for the profile tab***/
@media only screen and (max-width: 600px) {
    .cp-label{
      width: 100%;
    }



    .cs-account-section-mob{
      margin-bottom: 100px;
    }
  }