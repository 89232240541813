.terms-container {
  display: flex;
  padding: 112px 64px;
  flex-direction: column;
  align-items: center;
  gap: 80px;
}
.terms-head-main {
  display: flex;

  flex-direction: column;
  align-items: center;
  gap: 24px;
  color: #000;
}
.m-heading {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  color: var(--black, #000);
  text-align: center;

  /* Heading/H1 */
  font-family: Roboto;
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}
.m-date {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  color: var(--black, #000);
  text-align: center;

  /* Text/Medium/Normal */
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.terms-m-content {
  display: flex;

  padding: 0px 64px 112px 64px;
  flex-direction: column;
  align-items: center;
  gap: 80px;
  background: var(--white, #fff);
  max-width: 768px;
  margin: 0 auto;
}

.headings-m {
  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  color: #000;
}

.rich-text-m {
  display: flex;

  flex-direction: column;
  align-items: flex-start;
}
.h2-m {
  display: flex;
  padding: 32px 0px 16px 0px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.heading-2-m {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  color: var(--black, #000);

  /* Heading/H2 */
  font-family: Roboto;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}
.p-h2-m {
  display: flex;
  padding: 20px 0px 16px 0px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  color: #000;
}

.h2-bold-m {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  color: var(--black, #000);

  /* Heading/H6 */
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
}

.p-light-m {
  display: flex;
  padding-bottom: 0px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.h2-light-m {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  color: var(--black, #000);

  /* Text/Regular/Normal */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

/* -----h3---- */

.h3-m {
  display: flex;
  padding: 32px 0px 16px 0px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  color: #000;
}

.heading-3-m {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  color: var(--black, #000);

  /* Heading/H3 */
  font-family: Roboto;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

.p-h3-m {
  display: flex;
  padding-bottom: 15px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.h3-bold-m {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  color: var(--black, #000);

  /* Text/Regular/Normal */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.p-h3-light-m {
  display: flex;
  padding-bottom: 5px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.h3-light-m {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  color: var(--black, #000);

  /* Text/Regular/Normal */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

/* -------h4----- */

.h4-m {
  display: flex;
  padding: 24px 0px 16px 0px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.heading-4-m {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  color: var(--black, #000);

  /* Heading/H4 */
  font-family: Roboto;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
}

.p-h4-m {
  display: flex;
  padding-bottom: 6px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.h4-bold-m {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  color: var(--black, #000);

  /* Text/Regular/Normal */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

/* ----h5------ */

.h5-m {
  display: flex;
  padding: 24px 0px 16px 0px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.heading-5-m {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  color: var(--black, #000);

  /* Heading/H5 */
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
}

.p-h5-m {
  display: flex;
  padding-bottom: 0px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.h5-bold-m {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  color: var(--black, #000);

  /* Text/Regular/Normal */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.p-h5-light-m {
  display: flex;
  padding: 20px 0px 32px 0px;
  align-items: flex-start;
  align-self: stretch;
}

.divider-m {
  display: flex;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
  background: rgba(255, 255, 255, 0);
}
.divide-m {
  width: 2px;
  align-self: stretch;
  background: var(--black, #000);
}

.h5-light-m {
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  color: var(--black, #000);

  /* Other/Quote */
  font-family: Inter;
  font-size: 20px;
  font-style: italic;
  font-weight: 400;
  line-height: 28px;
}

/* ---------h6----- */

.h6-m {
  display: flex;
  padding: 24px 0px 16px 0px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.heading-6-m {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  color: var(--black, #000);

  /* Heading/H6 */
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
}

.p-h6-m {
  display: flex;
  padding-bottom: 6px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.h6-bold-m {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  color: var(--black, #000);

  /* Text/Regular/Normal */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
@media screen and (max-width: 480px) {
  .terms-head-main {
    display: flex;

    flex-direction: column;
    align-items: center;
    gap: 24px;
    color: #000;
  }
  .terms-container {
    display: flex;
    padding: 116px 1px;
    flex-direction: column;
    align-items: center;
    gap: 80px;
  }
}
