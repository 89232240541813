.lang-change-arrow {
  margin-left: 20px;
}
.lan-change-option-drpdown {
  color: rgba(0, 0, 0, 0.87);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 15px; /* 107.143% */
}
.selectBox-langu-change, .selectBox-langu-change-footer {
  padding: 10px 0px;
  /* display: flex; */
  /* width: 53.89px; */
  width: 70px;
  /* height: 17.6px; */
  /* flex-direction: column; */
  /* justify-content: center; */
  /* flex-shrink: 0; */
  color: rgba(0, 0, 0, 0.87);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 15px; /* 107.143% */
  background-color: #fff;

}
.selectBox-langu-change-footer{
  color: #fff;
  background-color: transparent;
  width: 90px;

}
/* 
@media screen and(max-width: 480px){
.static-top-bar-head {
    background: var(--white, #fff);
    box-shadow: 0px 2px 4px -2px rgba(0, 0, 0, 0.1), 0px 4px 8px -2px rgba(0, 0, 0, 0.1);
   
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
  }
} */

.custom-select-lan-ch {
  position: relative;
  display: inline-block;
}

.custom-select-lan-ch select {
  /* display: none;  */
  /* Hide the default select element */
  padding-right: 20px;
}

.custom-select-lan-ch .custom-arrow {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #eae4e4; /* Customize the arrow color */
  cursor: pointer;
}

.custom-select-lan-ch select option {
  padding: 100px;
}

.custom-select-lan-ch select option:hover {
  background-color: #8b93d7; /* Customize the hover background color */
}

.flag-icon {
  /* width: 16px; */
  /* height: 11px; */
  margin-right: 10px;
  width: 20px; 
  height: auto;
  /* margin-right: 5px; */
}