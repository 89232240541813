.cross-icon-div{
    display: flex;
padding: 8px;
justify-content: flex-end;
align-items: flex-start;
gap: 8px;
align-self: stretch;
}
.Employee-icon-img{
    width: 24px;
height: 24px;
flex-shrink: 0;
}

.Employee-Mobile-card {
  width: 306px;
  height: 822px;
  background: #ffffff;
  border: 1px solid #d7d7d7;
  box-shadow: 0px 4px 8px -2px rgba(0, 0, 0, 0.1),
    0px 2px 4px -2px rgba(0, 0, 0, 0.06);
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 1;
}
.Employee-text {
  color: var(--white, #fff);
  text-align: center;
  /* Text/Small/Medium */
  font-size: 14px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  border-radius: 20px;
  background: var(--brand-color, #04aff0);
  display: flex;
  padding: 0px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
.Employee-text-div {
  display: flex;
  padding: 13px 22px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}
.Employee-img-div {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  padding: 2px 20px;
}
.Employee-profile-img {
  width: 55px;
  height: 55px;
  border-radius: 165.476px;
  border: 3px solid var(--white, #fff);
  background: 
   lightgray 50% / cover no-repeat;
  box-shadow: 0px 2.3809516429901123px 4.761903285980225px -2.3809516429901123px
      rgba(0, 0, 0, 0.06),
    0px 4.761903285980225px 9.52380657196045px -2.3809516429901123px rgba(0, 0, 0, 0.1);
}

.Employee-name{
    color: var(--text-dark, #403D39);
/* Heading/Regualr/H6 */
font-size: 20px;
font-family: Roboto;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.Employee-camera-img{
    width: 24px;
height: 24px;
flex-shrink: 0;
border-radius: 24px;
background: var(--light-grey, #F4F4F4);
}
.img-text{
    color: #0057B0;

/* Text/Small/Normal */
font-size: 14px;
font-family: Roboto;
font-style: normal;
font-weight: 400;
line-height: 150%;
}
.name-area{
    display: flex;
flex-direction: column;
justify-content: space-between;
align-items: flex-start;
flex: 1 0 0;
align-self: stretch;
}
.image-area{
    display: flex;
align-items: center;
gap: 8px;
}

.cross-icon-img{
  cursor: pointer;
}