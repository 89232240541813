@media (min-width: 576px) and (max-width : 768px){
    .visiblemaker {
        display: block !important;
    }
}


.footervis {
    display: none;
}

@media (min-width: 576px) and (max-width: 768px) {
    .footervis {
        display: block !important;
    }
}