.contact-loc-p {
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
}

/* header end here */
/* Home section start here */
.banner-area-contact {
  display: flex;
  width: 100%;

  align-items: flex-start;
  gap: 35px;
}
.contact-a-tag {
  color: var(--text-dark, #403d39);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
}
.country-location-name {
  color: var(--text-dark, #403d39);
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 33.6px */
}
.content-contact-head {
  display: flex;
  width: 100%;
  padding: 112px 64px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  /* position: absolute; */
  margin: 58px 0px 0px 0px;
  background-image: url("../../Assets/Images/Bannercontact.png");
}
.error-msg-c {
  color: red;
}

.first-head-conct {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  color: var(--color-white);

  /* Heading/H2 */
  font-family: Roboto;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}
.picture-home-cotact {
  width: 100%;
}
.second-head-conct {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  color: var(--color-white);
  max-width: 768px;
  /* font-family: Roboto; */
  /* font-size: 18px; */
  /* font-weight: 400; */
  /* line-height: 120%; */
  /* color: var(--text-dark, #403d39); */
  color: #fff;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
}
/* Home section send here */
/* Blank section start */
.blank {
  display: flex;
  width: 1440px;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
}
.blank .img {
  width: 100px;
  height: 100px;
}
/* Blank section end */

/* contact module start */
.contact {
  display: flex;
  padding: 40px 0px;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  background: var(--color-label-blue);
}

.contact .contact-form-us {
  display: flex;
  width: 720px;
  padding: 80px 64px;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  background: var(--color-white);

  /* small */
  box-shadow: 0px 2px 4px -2px rgba(0, 0, 0, 0.06),
    0px 4px 8px -2px rgba(0, 0, 0, 0.1);
}

.contact .contact-form-us .title {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}

.contact .contact-form-us .title h2 {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  color: var(--color-text-dark);
  text-align: center;

  /* Heading/H2 */
  font-family: Roboto;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

.contact .contact-form-us .title p {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  color: var(--color-text-dark);
  text-align: center;

  /* Text/Medium/Normal */
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.contact .contact-form-us .form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: stretch;
}

.contact .contact-form-us .form .row {
  display: flex;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}

.contact .contact-form-us .form .row .first {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
}

.contact .contact-form-us .form .row .first {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  color: var(--color-text-dark);

  /* Text/Regular/Normal */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.contact .contact-form-us .form .row .first input {
  display: flex;
  height: 48px;
  padding: 12px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid var(--color-inputbox);
  background: var(--color-white);
}

.contact .contact-form-us .form .radios {
  display: flex;
  padding: 16px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.contact .contact-form-us .form .radios p {
  color: var(--color-text-dark);

  /* Text/Regular/Normal */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.contact .contact-form-us .form .radios .content {
  display: flex;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}

.contact .contact-form-us .form .radios .content .col-cotact-us1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
  flex: 1 0 0;
}

.contact .contact-form-us .form .radios .content .col-cotact-us1 .radio {
  display: flex;
  align-items: center;
  gap: 12px;
}

.contact .contact-form-us .form .msg {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.contact .contact-form-us .form .msg {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  color: var(--color-text-dark);

  /* Text/Regular/Normal */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.contact .contact-form-us .form .msg textarea {
  display: flex;
  height: 180px;
  padding: 12px;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid var(--color-inputbox);
  background: var(--color-white);
}

.contact .contact-form-us .form .msg textarea::placeholder {
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  color: var(--color-neutral);

  /* Text/Regular/Normal */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.contact .contact-form-us .form .checkbox {
  display: flex;
  padding-bottom: 0px;
  align-items: center;
  gap: 12px;
}

.contact .contact-form-us .form .checkbox input {
  width: 18px;
  height: 18px;
  border: 1px solid var(--color-text-dark);
  background: var(--color-text-white);
}

.contact .contact-form-us .form .checkbox input label {
  color: var(--color-text-dark);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.contact .contact-form-us .form button {
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 30px;
  background: var(--color-brand-color);
  color: var(--color-white);

  /* Text/Regular/Normal */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

/* contact module end */

/* Row start here */
.info {
  display: flex;
  width: 100%;
  padding: 112px 64px;
  align-items: flex-start;
  gap: 48px;
  background: linear-gradient(90deg, #0057b0 0%, #04aff0 100%), #fff;
}

.info .email-contact-se {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  flex: 1 0 0;
}

.info .email-contact-se .frame-conact-us {
  border-radius: 35px;
  background: #fff;
  display: flex;
  padding: 8px;
  align-items: flex-start;
  gap: 8px;
}

.info .email-contact-se .frame-conact-us svg {
  width: 48px;
  height: 48px;
}

.info .email-contact-se .para-conta {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}

.info .email-contact-se .para-conta .col-cotact-us {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.info .email-contact-se .para-conta .col-cotact-us h4 {
  color: var(--color-text-white);
  text-align: center;

  /* Heading/H4 */
  font-family: Roboto;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  display: flex;
  flex-direction: column;
  align-self: stretch;
}

.info .email-contact-se .para-conta .col-cotact-us p {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  color: var(--color-text-white);
  text-align: center;

  /* Text/Regular/Normal */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.info .email-contact-se .para-conta a {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  color: var(--color-white);
  text-align: center;

  /* Text/Regular/Link */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration-line: underline;
}

/* Row end here */
/* location start from here */

.location {
  display: flex;
  width: 100%;
  padding: 112px 100px;
  flex-direction: column;
  align-items: flex-start;
  gap: 80px;
  background: var(--color-white);
}

.location .sec1 {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.location .sec1 p {
  color: var(--color-text-dark);
  text-align: center;

  /* Text/Regular/Semi Bold */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}
.contact-ic {
  color: #04aff0;
}
.location .sec1 h2 {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  color: var(--color-text-dark);

  /* Heading/H2 */
  font-family: Roboto;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

.location .sec2 {
  display: flex;
  /* align-items: flex-start; */
  gap: 80px;
  width: 100%;
}

.location .sec2 .tab {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  max-width: 468px;
}

.location .sec2 .tab .horizontal {
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
}

.location .sec2 .tab .horizontal .con {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.location .sec2 .tab .horizontal .con h5 {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  color: var(--color-text-dark);

  /* Heading/H5 */
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
}
.location .sec2 .tab .horizontal .con p {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  color: var(--color-text-dark);

  /* Text/Regular/Normal */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.location .sec2 .tab .horizontal a {
  color: var(--color-text-dark);

  /* Text/Regular/Semi Bold */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  display: flex;
  flex-direction: column;
  align-self: stretch;
}
.contact-us-page-map-div {
  width: 100%;
  border-radius: 12px;
  max-width: 700px;
}
.location .sec2 .placehold {
  width: 100%;

  border-radius: 12px;
}
.mobile-map-contact {
  display: none;
}

/* location end from here */

@media screen and (max-width: 480px) {
  .picture-home-cotact {
    display: none;
  }
  .content-contact-head {
    display: flex;
    width: 100%;
    padding: 52px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    position: relative;
    background: linear-gradient(90deg, #0057b0 0%, #04aff0 100%),
      linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
      lightgray 50% / cover no-repeat;
  }
  .info {
    display: flex;
    width: 100%;
    padding: 107px 25px;
    align-items: flex-start;
    gap: 48px;
    background: linear-gradient(90deg, #0057b0 0%, #04aff0 100%), #fff;
    flex-direction: column;
  }

  .location {
    display: flex;
    width: 100%;
    padding: 112px 25px;
    flex-direction: column;
    align-items: flex-start;
    gap: 80px;
    background: var(--color-white);
  }
  .location .sec2 {
    display: block;
  }
  .mobile-map-contact {
    display: block;
    width: 100%;
  }
  .desktop-map-img {
    display: none;
  }
  .contact .contact-form-us .form .row {
    display: flex;
    align-items: flex-start;

    align-self: stretch;
  }
  .contact .contact-form-us {
    display: flex;
    width: 720px;
    padding: 80px 33px;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
    background: var(--color-white);
    box-shadow: 0px 2px 4px -2px rgba(0, 0, 0, 0.06),
      0px 4px 8px -2px rgba(0, 0, 0, 0.1);
  }
  .location .sec1 p {
    color: var(--color-text-dark);

    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
  }
  .cont-text-form {
    text-align: left;
  }
}
