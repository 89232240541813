a{
    text-decoration: none;
}

.header-privacy-p{
    display: flex;

height: 326px;
padding: 112px 64px;
flex-direction: column;
align-items: flex-start;
gap: 80px;

}

.content-privacy-p{
    display: flex;
width: 768px;
flex-direction: column;
align-items: flex-start;
gap: 24px;
flex: 1 0 0;
}

.heading-privacy-p{
    display: flex;
flex-direction: column;
align-self: stretch;
color: var(--black, #000);

/* Heading/H1 */
font-family: Roboto;
font-size: 56px;
font-style: normal;
font-weight: 700;
line-height: 120%;
}

.date-privacy-p{
    display: flex;
flex-direction: column;
align-self: stretch;
color: var(--black, #000);

/* Text/Medium/Normal */
font-family: Roboto;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 150%;
}

/* ----------------content--section-------- */

.container-privacy-p{
    display: flex;
padding: 0px 64px 112px 64px;
flex-direction: column;
align-items: center;
gap: 80px;
background: var(--white, #FFF);
}

.contain-privacy-p{
    display: flex;
justify-content: space-around;
align-items: flex-start;
align-self: flex-start;
column-gap: 50px;

}

.left-privacy-p{
    display: flex;
width: 320px;
flex-direction: column;
align-items: flex-start;
gap: 24px;
}

.table-privacy-p{
    color: var(--black, #000);

/* Heading/H5 */
font-family: Roboto;
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: 140%;
}

.table-heading-privacy-p{
    display: flex;
flex-direction: column;
align-items: flex-start;
align-self: stretch;
}

.table-h2-privacy-p{
    display: flex;
padding: 12px 16px;
align-items: flex-start;
gap: 10px;
align-self: stretch;
background: var(--light-grey, #F4F4F4);
}

.table-h3-privacy-p{
    display: flex;
padding: 12px 32px;
align-items: flex-start;
gap: 8px;
align-self: stretch;
color: #000;
}

.table-h4-privacy-p{
    display: flex;
padding: 12px 48px;
align-items: flex-start;
gap: 8px;
align-self: stretch;
color: #000;
}

.table-h5-privacy-p{
    display: flex;
padding: 12px 64px;
align-items: flex-start;
gap: 8px;
align-self: stretch;
color: #000;
}

.table-h6-privacy-p{
    display: flex;
padding: 12px 80px;
align-items: flex-start;
gap: 8px;
align-self: stretch;
color: #000;
}


/* ---------right------ */

.right-privacy-p{
    display: flex;
width: 768px;
flex-direction: column;
align-items: flex-start;
}

.h2-privacy-p{
    display: flex;
padding: 32px 0px 16px 0px;
flex-direction: column;
align-items: flex-start;
align-self: stretch;
}

.heading-2-privacy-p{
    display: flex;
flex-direction: column;
align-self: stretch;
color: var(--black, #000);

/* Heading/H2 */
font-family: Roboto;
font-size: 48px;
font-style: normal;
font-weight: 700;
line-height: 120%;
}

.p-h2-privacy-p{
    display: flex;
padding: 20px 0px 16px 0px;
flex-direction: column;
align-items: flex-start;
align-self: stretch;
}

.h2-bold-privacy-p{

    display: flex;
flex-direction: column;
align-self: stretch;
color: var(--black, #000);

/* Heading/H6 */
font-family: Roboto;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 140%;
}

.p-light-privacy-p{
    display: flex;
padding-bottom: 5px;
flex-direction: column;
align-items: flex-start;
align-self: stretch;
}

.h2-light-privacy-p{
    display: flex;
flex-direction: column;
align-self: stretch;
color: var(--black, #000);

/* Text/Regular/Normal */
font-family: Roboto;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 150%;
}

.h3-privacy-p{
    display: flex;
padding: 32px 0px 16px 0px;
flex-direction: column;
align-items: flex-start;
align-self: stretch;
}

.heading-3-privacy-p{
    display: flex;
flex-direction: column;
align-self: stretch;
color: var(--black, #000);

/* Heading/H3 */
font-family: Roboto;
font-size: 40px;
font-style: normal;
font-weight: 700;
line-height: 120%;
}

.p-h3-privacy-p{
    display: flex;
padding-bottom: 0px;
flex-direction: column;
align-items: flex-start;
align-self: stretch;
}

.h3-bold-privacy-p{
    display: flex;
flex-direction: column;
align-self: stretch;
color: var(--black, #000);

/* Text/Regular/Normal */
font-family: Roboto;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 150%;
}


.p-h3-light-privacy-p{
    display: flex;
padding-bottom: 5px;
flex-direction: column;
align-items: flex-start;
align-self: stretch;
}

.h3-light-privacy-p{
    display: flex;
flex-direction: column;
align-self: stretch;
color: var(--black, #000);

/* Text/Regular/Normal */
font-family: Roboto;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 150%;
}

.h4-privacy-p{
    display: flex;
padding: 24px 0px 16px 0px;
flex-direction: column;
align-items: flex-start;
align-self: stretch;
}

.heading-4-privacy-p{
    display: flex;
flex-direction: column;
align-self: stretch;
color: var(--black, #000);

/* Heading/H4 */
font-family: Roboto;
font-size: 32px;
font-style: normal;
font-weight: 700;
line-height: 130%;
}

.p-h4-privacy-p{
    display: flex;
padding-bottom: 0px;
flex-direction: column;
align-items: flex-start;
align-self: stretch;
}

.h4-bold-privacy-p{
    display: flex;
flex-direction: column;
align-self: stretch;
color: var(--black, #000);

/* Text/Regular/Normal */
font-family: Roboto;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 150%;
}

.h5-privacy-p{
    display: flex;
padding: 24px 0px 16px 0px;
flex-direction: column;
align-items: flex-start;
align-self: stretch;
}

.heading-5-privacy-p{
    display: flex;
flex-direction: column;
align-self: stretch;
color: var(--black, #000);

/* Heading/H5 */
font-family: Roboto;
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: 140%;
}

.p-h5-privacy-p{
    display: flex;
padding-bottom: 0px;
flex-direction: column;
align-items: flex-start;
align-self: stretch;
}

.h5-bold-privacy-p{

    display: flex;
flex-direction: column;
align-self: stretch;
    color: var(--black, #000);

/* Text/Regular/Normal */
font-family: Roboto;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 150%;
}

.p-h5-light-privacy-p{
    display: flex;
padding: 20px 0px 32px 0px;
align-items: flex-start;
align-self: stretch;
}

.divider-privacy-p{
    display: flex;
align-items: flex-start;
gap: 24px;
flex: 1 0 0;
background: rgba(255, 255, 255, 0.00);
}

.divide-privacy-p{
    width: 2px;
align-self: stretch;
background: var(--black, #000);
}

.h5-light-privacy-p{
    display: flex;
flex-direction: column;
flex: 1 0 0;
color: var(--black, #000);

/* Other/Quote */
font-family: Inter;
font-size: 20px;
font-style: italic;
font-weight: 400;
line-height: 28px;
}

.h6-privacy-p{
    display: flex;
padding: 24px 0px 16px 0px;
flex-direction: column;
align-items: flex-start;
align-self: stretch;
}

.heading-6-privacy-p{
    display: flex;
flex-direction: column;
align-self: stretch;
color: var(--black, #000);

/* Heading/H6 */
font-family: Roboto;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 140%;
}


p.h6-privacy-p{
    display: flex;
padding-bottom: 6px;
flex-direction: column;
align-items: flex-start;
align-self: stretch;
}

.h6-bold-privacy-p{
    display: flex;
flex-direction: column;
align-self: stretch;
color: var(--black, #000);

/* Text/Regular/Normal */
font-family: Roboto;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 150%;
}

@media screen and (max-width: 480px){


    .header-privacy-p{
        width: 430px;
        height: 200px;
    }

    .content-privacy-p{
        width: 430px;
        height: 100px;
    }

    .heading-privacy-p{
        width: 430px;
    }
    .date-privacy-p{
        width: 440px;
        font-size: 15px;
    }

    .container-privacy-p{
        width: 712px;
    }

    .contain-privacy-p{
        display: block;
    }
    .left-privacy-p{
        width: 440px;
        margin-left: 100px;
    }

    .table-privacy-p{
        width: 440px;
    }
    .table-heading-privacy-p{
        width: 400px;
    }

    .right-privacy-p{
       
        width: 100%;
    }
}