.backbutton-f {
  margin-left: -379px;
  margin-top: 50px;
}
.forgotpassword-container {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.forgotpasswordheader {
  font-size: 24px;
  font-weight: 700;
  color: var(--color-text-dark);
  margin-top: 10px;
  margin-bottom: 8px;
}
.forgotpasswordparagraph {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #8692a6;
  margin-top: 10px;
  margin-bottom: 32px;
}
 .forgotpasswordform {
  margin-top: 70px;
  width: 100%;
  margin-left: 57px;
} 
.Signup-button {
  background-color: var(--color-white);
  color: var(--color-brand-color);
  font-weight: 700;
  font-size: 14px;
}
.footer-content-forgotpassword {
  margin-bottom: 500px;
  margin-left: 80px;
  margin-top: 40px;
}

@media screen and (max-width: 480px) {
  .forgotpassword-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .forgotpasswordform {
    width:90%;
    margin-left: 0px;
    margin-top: 20px;
  }
  .footer-content-forgotpassword {
    margin-left: 80px;
    margin-top: 50px;
    margin-bottom: 30px;
  }

  .footer-fp {
    margin-bottom: 0px;
    margin-left: -90px;
   
  }
  .backbutton-f{
    display: block;
    margin-left: 9px;
    margin-top: 9px;
  }
}
