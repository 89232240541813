.modal-container-dcp {
  width: 420px;
  height: 205px;
  border-radius: 12px;
  top: 30vh;
}
.header-dcp {
  border: none;
  padding-bottom: 0px;
  padding-left: 20px;
}
.title-dcp {
  font-size: 18px;
  font-weight: 600;
  padding-top: 10px;
}
.body-dcp {
  font-size: 14px;
  padding-top: 5px;
  padding-left: 20px;
  color: var(--color-text-light);
}
.footer-dcp {
  border: none;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding-top: 0px;
}
.close-dcp {
  width: 180px;
  height: 44px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  background-color: var(--color-white);
  font-weight: 600;
}
.delete-dcp {
  width: 180px;
  height: 44px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  border: 1px solid #04aff0;
  background-color: var(--color-brand-color);
  color: var(--color-white);
}
