.emp-cancel-btns-div {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

.emp-save-btn {
  width: 107px;
  height: 43px;
  font-weight: 400;
  font-size: 20px;
}

.emp-cancel-btn {
  color: #403d39;
  width: 107px;
  height: 43px;
  background-color: #fff;
  border: 1px solid #d7d7d7;
  font-weight: 400;
  font-size: 20px;
}

.emp-update-text {
  color: #7b7b7b;
  font-size: 16px;
}
.emp-form-div {
  width: 100%;
}
.emp-form-label-input {
  display: flex;
  align-items: center;
}
.emp-form-label {
  color: #7b7b7b;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  width: 40%;
}
.dropdown-icon-prof {
  position: absolute;
  top: 72%;
  right: 146px;
  transform: translateY(-49%);
  width: 36px;
  height: 46px;

  stroke-width: -0.333px;
  color: var(--text-light, #7b7b7b);
}
.emp-form-input {
  height: 50px;
  background: #ffffff;
  border: 1px solid #d7d7d7;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  width: 100%;
  padding: 10px;
}

.error-msg {
  color: red;
}
.emp-profile-card {
  background: #ffffff;
  height: auto;
  width: 100%;
  border: 1px solid #d7d7d7;
  box-shadow: 0px 4px 8px -2px rgba(0, 0, 0, 0.1),
    0px 2px 4px -2px rgba(0, 0, 0, 0.06);
  border-radius: 20px;
  padding: 20px;
}
.emp-experience {
  align-items: center;
  padding: 4px 12px;

  width: 59px;
  height: 29px;

  /* Chip Blue */

  background: #eff5ff;
  border-radius: 16px;
  display: inline;
  margin-right: 10px;
}
error-msg {
  margin-right: 15px;
}

.profileeditinputs div {
  max-height: 200px; /* Adjust the value as needed */
  /* overflow-y: auto; */
}
.cp-form-label-input-proedit {
  display: flex;

  gap: 32px;

  align-items: center;
}

.cp-form-label-proedit {
  color: #7b7b7b;

  font-family: "Roboto";

  font-style: normal;

  font-weight: 400;

  font-size: 20px;

  width: 100%; /* Set the label width to 100% */
}
.cp-form-label-proedit-emp {
  color: #7b7b7b;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  width: 100%;
}
.emp-proedit-text-editor {
  width: 100%; 
  max-width: 512px;
}

.select-box-country {
  height: 43px;
  background: #ffffff;
  border: 1px solid #a2a2a6;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  margin-top: 6px;
  margin-bottom: 3px;
  width: 100%;
  padding: 10px;
}

@media screen and (max-width: 480px) {
  /* .container-emp-profile-main {
    margin-top: -120px;
    padding-right: var(--bs-gutter-x);
    padding-left: var(--bs-gutter-x);
  } */
  .emp-label-div {
    display: flex;

    flex-direction: column;

    white-space: nowrap;
  }
  .emp-form-label-input {
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    gap: 10px;
  }
  .profile-edit-ip {
    max-height: 200px; /* Adjust the value as needed */
    width: 100%;
    /* overflow-y: auto; */
  }
  /* .emp-form-input{
width:100%
    } */

  .emp-form-label {
    color: #7b7b7b;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    width: 80%;
  }
  .cp-form-label-input-proedit {
    flex-direction: column;

    gap: 18px;

    align-items: flex-start;
  }
  .dropdown-icon-prof {
    position: absolute;
    top: 59%;
    right: 47px;
    transform: translateY(-49%);
    width: 36px;
    height: 46px;
    stroke-width: -0.333px;
    color: var(--text-light, #7b7b7b);
  }
}

.emp-cancel-btns-div {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

.emp-save-btn {
  width: 107px;
  height: 43px;
  font-weight: 400;
  font-size: 20px;
}

.emp-cancel-btn {
  color: #403d39;
  width: 107px;
  height: 43px;
  background-color: #fff;
  border: 1px solid #d7d7d7;
  font-weight: 400;
  font-size: 20px;
}

.emp-update-text {
  color: #7b7b7b;
  font-size: 16px;
}
.emp-form-div {
  width: 100%;
}
.emp-form-label-input {
  display: flex;
  align-items: center;
}
.emp-form-label {
  color: #7b7b7b;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  width: 40%;
}

.emp-form-input {
  height: 50px;
  background: #ffffff;
  border: 1px solid #d7d7d7;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  width: 100%;
  padding: 10px;
}

.error-msg {
  color: red;
}
.css-1p3m7a8-multiValue {
  background: var(--chip-blue, #eff5ff);
}

.emp-profile-card {
  background: #ffffff;
  height: auto;
  width: 100%;
  border: 1px solid #d7d7d7;
  box-shadow: 0px 4px 8px -2px rgba(0, 0, 0, 0.1),
    0px 2px 4px -2px rgba(0, 0, 0, 0.06);
  border-radius: 20px;
  padding: 20px;
  margin-bottom: 13px;
}
.emp-experience {
  align-items: center;
  padding: 4px 12px;

  width: 59px;
  height: 29px;

  /* Chip Blue */

  background: #eff5ff;
  border-radius: 16px;
  display: inline;
  margin-right: 10px;
}
error-msg {
  margin-right: 15px;
}

.profileeditinputs div {
  max-height: 200px; /* Adjust the value as needed */
  /* overflow-y: auto; */
}
.profile-edit-ip {
  max-height: 200px; /* Adjust the value as needed */
  width: 80%;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.react-multiselect-checkboxes .item-selected {
  white-space: nowrap; /* Prevent selected options from wrapping to new lines */
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  max-height: 100%; /* Set a fixed maximum height to prevent expansion */
  overflow-y: auto; /* Enable vertical scrolling when options exceed the height */
  display: flex;
  flex-direction: column;
  gap: 10px;
  white-space: nowrap; /* Prevent selected options from wrapping to new lines */
  overflow: hidden;
  text-overflow: ellipsis;
}
.emp-form-label-div-proedit {
  width: 45%;
}
.emp-profileeditinput-proedit {
  width: 100%;
  color: #7b7b7b;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
}
.emp-form-label-input-proedit {
  display: flex;
  gap: 32px;
  align-items: center;
}

@media screen and (max-width: 480px) {
  /* .container-emp-profile-main{
      margin-top: -120px;
      padding-right: var(--bs-gutter-x);
      padding-left: var(--bs-gutter-x);
    } */
  .emp-label-div {
    display: flex;

    flex-direction: column;

    white-space: nowrap;
  }
  .emp-form-label-input {
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    gap: 10px;
  }
  .profile-edit-ip {
    max-height: 200px; /* Adjust the value as needed */
    width: 100%;
    /* overflow-y: auto; */
  }
  /* .emp-form-input{
width:100%
    } */

  .emp-form-label {
    color: #7b7b7b;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    width: 80%;
  }
  .emp-form-label-input-proedit {
    display: flex;
    gap: 7px;
    align-items: center;
    flex-direction: column;
  }
  .emp-form-label-div-proedit {
    color: #7b7b7b;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    width: 100%;
  }
  .emp-proedit-text-editor {
    max-width: 312px;
  }
  
}
