
.emp-profile-card4 {
    background: #ffffff;
    height: auto;
    width: 100%;
    border: 1px solid #d7d7d7;
    box-shadow: 0px 4px 8px -2px rgba(0, 0, 0, 0.1),
      0px 2px 4px -2px rgba(0, 0, 0, 0.06);
    border-radius: 20px;
    padding: 20px;
    margin-bottom: 80px;
  }
