.modal-container-thy {
  max-width: 410px;
  width: 100%;
  height: 239px;
  border-radius: 12px;
  /* top:30vh;
    right:2vh; */
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.header-thy {
  border: none;
  padding-bottom: 0px;
  padding-top: 25px;
  padding-left: 0px;
}
.title-thy {
  font-size: 18px;
  font-weight: 600;
  padding-left: 22px;
}
.body-thy {
  font-size: 14px;
  padding-top: 10px;
  padding-left: 22px;
  color: var(--color-text-light);
}
.footer-thy {
  border: none;
  padding-bottom: 20px;
  padding-top: 9px;
  padding-left: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.close-thy {
  max-width: 360px;
  width: 100%;
  height: 44px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  border: 1px solid #04aff0;
  background-color: var(--color-brand-color);
  color: var(--color-white);
}
.close-thy-div{
    /* max-width: 280px; */
    width: 100%;

}
@media screen and (max-width: 896px) {
  .modal-container-thy {
    max-width: 305px;
  height: 260px;

  }
  .close-thy {
    max-width: 280px;
}
.close-thy-div{
    max-width: 280px;

}
}
