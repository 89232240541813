.psb-card {
  width: 277px;
  height: 288px;
  background: #ffffff;
  border: 1px solid #d7d7d7;
  box-shadow: 0px 4px 8px -2px rgba(0, 0, 0, 0.1),
    0px 2px 4px -2px rgba(0, 0, 0, 0.06);
  border-radius: 20px;
}

.psb-cust-text {
  width: 70px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  background: #04aff0;
  border-radius: 20px;
  font-weight: 500;
  font-size: 12px;
}

.psb-cust-text-div {
  display: flex;
  justify-content: flex-end;
  padding-top: 12px;
}

.psb-profile-img {
  width: 195px;
  height: 195px;
  border: 3px solid #ffffff;
  box-shadow: 0px 4.7619px 9.52381px -2.38095px rgba(0, 0, 0, 0.1),
    0px 2.38095px 4.7619px -2.38095px rgba(0, 0, 0, 0.06);
  border-radius: 165.476px;
}

.psb-profile-img-div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.psb-name {
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #403d39;
  display: flex;
  justify-content: center;
  align-items: center;
}

.psb-profile-img {
  z-index: 1;
}
.psb-camera-img {
  z-index: 2;
  padding-top: 95px;
  position: absolute;
  padding-left: 185px;
  cursor: pointer;
}

.psb-btn:hover {
  background: #04aff0;
  color: #ffffff;
}

.psb-about-div {
  width: 277px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.psb-about-div p {
  color: #7b7b7b;
  font-size: 16px;
  cursor: pointer;
}
.px-2 {
  font-size: 16px;
}
.psb-terms {
  display: flex;
  justify-content: center;
  width: 277px;
  color: #7b7b7b;
  font-size: 16px;
  cursor: pointer;
}
.psb-btn {
  display: flex;
  align-items: center;
  width: 277px;
  height: 44px;
  background: white;
  border: 1px solid #d7d7d7;
  box-shadow: 0px 4px 8px -2px rgba(0, 0, 0, 0.1),
    0px 2px 4px -2px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  font-size: 20px;
  line-height: 23px;
  color: black;
  padding-left: 55px;
  cursor: pointer;
}

.psb-btn-active {
  display: flex;
  align-items: center;
  width: 277px;
  height: 44px;
  background: #04aff0;
  color: #ffffff;
  border: 1px solid #d7d7d7;
  box-shadow: 0px 4px 8px -2px rgba(0, 0, 0, 0.1),
    0px 2px 4px -2px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  font-size: 20px;
  line-height: 23px;
  padding-left: 55px;
  cursor: pointer;
}
.psb-profile-img-placeholder {
  filter: blur(0px);
  /* filter: blur(20px); */
}
.psb-profile-img.hidden {
  display: none;
}
.Employee-psb-profile-img.hidden {
  display: none;
}