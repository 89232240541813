.dropzone-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 512px;
  height: 133px;
  border: 2px dashed #ccc;
  border-radius: 4px;
  padding: 16px;
  text-align: center;
  font-size: 16px;
  color: #555;
  cursor: pointer;
  position: relative;
}

.upload-icon {
  font-size: 48px;
  margin-bottom: 16px;
}
.checkb-image {
  /* padding-left: 464px; */
  margin-top: -29px;
  margin-left: -60%;
}
.img_area_text{
  display:flex;
   justify-content:space-between;
   align-items:center;
   gap: 278px;
   margin-bottom: 33px;
}
.dropzone-container.uploaded {
  border: 1px solid #04aff0;
  width: 512px;
  height: 108px;
  border-radius: 8px;
}
.drag-container {
  line-height: 1.5;
}

.uploaded-image {
  width: 24px; 
  height: 24px; 
  margin-right: 8px; 
}

.filename-image {
  gap: 20px;
}

.progress-with-text {
  position: absolute;
  left: 46px;
  right: 20px;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  
}



.progress-bar-container {
  flex-grow: 1;
}

.progress {
  flex: 1;
}
.image-text {
  display: flex;
  align-items: flex-start;
  line-height: 1.5;
  padding-top: 7px;
  margin-left: 1%;
 

}

 

.file-info {
  display: flex;
  flex-direction: column;
}
.uploaded-file-info{
column-gap: 10px;
   
}
@media screen and (max-width: 896px) {
  .dropzone-container.uploaded, .uploaded-file-info{
  width: 284px;

  }
 
}

@media screen and (max-width: 480px) {
  .emp-label-div {
    display: flex;

    flex-direction: column;

    white-space: nowrap;
    
  }
  .emp-label{
    width: 100%;
    word-wrap:break-word;
  }
  .dropzone-container {
    width: 100%;
  }
  .dropzone-container.uploaded {
    width:100%;
  }
 
  .img_area_text{
    gap:61px;
  }
 

.progress-with-text{
  left: 14px;
}


}