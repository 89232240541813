/* Home section end here */
/* Layout start here */

.content-head-sec-about {
  display: flex;
  width: 100%;
  padding: 112px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  position: absolute;
}
.column-about-sec {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
}
.about-section-our-values-tag {
  color: var(--text-dark, #403d39);
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
}
.about-section-our-values-heading {
  color: var(--text-dark, #403d39);
  font-family: Roboto;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 57.6px */
  max-width: 768px;
}
.about-section-our-values-para {
  color: var(--text-dark, #403d39);
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 27px */
  max-width: 768px;
}
.column-about-sec-heading {
  color: var(--text-dark, #403d39);
  font-family: Roboto;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 57.6px */
}
.column-about-sec-tag {
  color: var(--text-dark, #403d39);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
}
.full-banner-about-sec {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  background: var(--chip-blue, #eff5ff);
}

.banner-area-about-sec {
  display: flex;
  /* height: 576px; */
  align-self: stretch;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
}
.slider-btn-home {
  display: flex;
  align-items: flex-start;
  gap: 15px;
}
.arrow-btn-home {
  display: flex;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 50px;
  border: 1px solid var(--text-dark, #f1ede9);
  cursor: pointer;
  background-color: white;
}

.about-sec-headi {
  color: var(--color-white);
  font-family: Roboto;
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  filter: blur(0px);
}
.about-sec-parag {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  color: var(--color-white);
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  filter: blur(0px);
}
.picture-about-sec {
  width: 100%;
}

.about-layout {
  display: flex;
  width: 100%;
  padding: 112px 80px;
  align-items: flex-start;
  gap: 80px;
  background: var(--text-white);
}

.about-layout.column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
}

.about-layout.column p {
  color: var(--color-text-dark);

  /* Text/Regular/Semi Bold */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}

.about-layout.column h2 {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  color: var(--color-text-dark);

  /* Heading/H2 */
  font-family: Roboto;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

.about-layout .Timeline {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
}

.about-layout .Timeline .Intern {
  display: flex;
  align-items: flex-start;
  gap: 40px;
  align-self: stretch;
}

.about-layout .Timeline .Intern .icons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.about-layout .Timeline .Intern .icons .component svg {
  width: 48px;
  height: 48px;
}

.about-layout.Timeline .Intern .icons .divider svg {
  width: 2px;
  height: 100px;
  background: #04aff0;
}

.about-layout .Timeline .Intern .text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  flex: 1 0 0;
}

.about-layout .Timeline .Intern .text h6 {
  display: flex;
  flex-direction: row;
  align-self: stretch;
  color: var(--color-text-dark);

  /* Text/Regular/Semi Bold */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}

.about-layout .Timeline .Intern .text h4 {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  color: var(--color-text-dark);

  /* Heading/H4 */
  font-family: Roboto;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
}
.about-layout .Timeline .Intern .text p {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  color: var(--color-text-dark);

  /* Text/Regular/Normal */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

/* Layout end here */
/* <!-- Start our vision from here -->*/
.about-frame-tag {
  color: var(--text-white, #fff);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
}
.about-vision {
  display: flex;
  width: 100%;
  padding: 112px 80px;

  align-items: flex-start;
  gap: 80px;
  background: linear-gradient(90deg, #0057b0 0%, #04aff0 100%);
}

.about-vision .about-frame {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}
.abo-text {
  display: flex;
  /* width: 1264px; */
  /* align-items: flex-start; */
  flex-wrap: wrap;
  gap: 80px;
}

.about-vision .about-frame p {
  color: var(--color-white);

  /* Text/Regular/Semi Bold */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}

.about-vision .about-frame.abo-text {
  display: flex;
  width: 100%;
  align-items: flex-start;
  gap: 70px;
}

.about-vision .about-frame .abo-text .left {
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  color: var(--color-text-white);
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}
.about-vision .about-frame .abo-text .right {
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  color: var(--color-text-white);

  /* Heading/Bold/H4 */
  font-family: Roboto;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
}

/* <!-- End our vision  here --> */

/* start our values from here */
.ab-values {
  display: flex;
  width: 100%;
  padding: 112px 80px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 80px;
  background: var(--color-white);
}

.ab-values .ab-section {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.ab-values .ab-section p {
  color: var(--color-text-dark);
  text-align: center;

  /* Text/Regular/Semi Bold */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}

.ab-values .ab-section .ab-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}

.ab-values .ab-section .ab-content h2 {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  color: var(--text-dark, #403d39);

  /* Heading/H2 */
  font-family: Roboto;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}
.ab-values .ab-section .ab-content h6 {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  color: var(--text-dark, #403d39);

  /* Text/Medium/Normal */
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.ab-values .ab-row {
  display: flex;
  align-items: flex-start;
  gap: 48px;
  align-self: stretch;
  flex-wrap: wrap;
  max-width: calc(3 * (400px + 48px));
}

.ab-values .ab-row .ab-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  /* flex: 1 0 0; */
  flex: 1 0 calc(33.333% - 48px);
}
.ab-values .ab-row .ab-column .img1,
.ab-values .ab-row .ab-column .img2,
.ab-values .ab-row .ab-column .img3 {
  width: 100%;
  max-width: 400px;
  height: auto;
}

.ab-values .ab-row .ab-column .img1 {
  height: 240px;
  align-self: stretch;
  /* background: url(../images/Placeholder/Sec1.png), lightgray 50% / cover no-repeat; */
}
.ab-values .ab-row .ab-column .img2 {
  height: 240px;
  align-self: stretch;
  /* background: url(../images/Placeholder/Sec2.png), lightgray 50% / cover no-repeat; */
}
.ab-values .ab-row .ab-column .img3 {
  height: 240px;
  align-self: stretch;
  /* background: url(../images/Placeholder/Sec3.png), lightgray 50% / cover no-repeat; */
}

.ab-values .ab-row .ab-column .ab-para {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}

.ab-values .ab-row .ab-column .ab-para h4 {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  color: var(--color-text-dark);

  /* Heading/H4 */
  font-family: Roboto;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
}
.ab-values .ab-row .ab-column .ab-para p {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  color: var(--color-text-dark);

  /* Text/Regular/Normal */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
/* End our values here */

/* team start here */

.ab-us-team {
  display: flex;
  width: 100%;
  padding: 60px 80px;
  flex-direction: column;
  align-items: flex-start;
  gap: 80px;
  background: linear-gradient(90deg, #0057b0 0%, #04aff0 100%), #fff;
}

.ab-us-team .ab-us-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: stretch;
}
.ab-us-content-heading {
  color: var(--text-white, #fff);
  font-family: Roboto;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 57.6px */
}
.ab-us-content-tagline {
  color: var(--text-white, #fff);
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 27px */
}
.ab-us-team .ab-us-content h2 {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  color: var(--color-white);

  /* Heading/H2 */
  font-family: Roboto;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

.ab-us-team .ab-us-content p {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  color: var(--color-white);

  /* Text/Medium/Normal */
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
/* .ab-us-members{
  margin-bottom: 80px;
} */
.ab-us-team .ab-us-members {
  display: flex;
  width: 100%;
  align-items: flex-start;
  gap: 32px;
}

.ab-us-team .ab-us-members .upper1 {
  display: flex;
  height: 480px;

  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  flex: 1 0 0;
  /* background: var(--avatar-user-portrait-alisa-hester, url(../images/Placeholder/_Team\ member.png), lightgray 50% / cover no-repeat); */
}
.ab-us-team .ab-us-members .upper2 {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: flex-start;
  gap: 32px;

  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  flex: 1 0 0;
  /* background: var(--avatar-user-portrait-alisa-hester, url(../images/Placeholder/_Team\ member1.png), lightgray 50% / cover no-repeat); */
}
.ab-us-team .ab-us-members .upper3 {
  display: flex;
  height: 100%;

  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  flex: 1 0 0;
  /* background: var(--avatar-user-portrait-alisa-hester, url(../images/Placeholder/_Team\ member3.png), lightgray 50% / cover no-repeat); */
}
.ab-us-team .ab-us-members .upper4 {
  display: flex;
  height: 100%;

  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  flex: 1 0 0;
  /* background: var(--avatar-user-portrait-alisa-hester, url(../images/Placeholder/_Team\ member4.png), lightgray 50% / cover no-repeat); */
}

/* team end here */

/* testinomial start from here */

.studies {
  display: flex;
  width: 1440px;
  padding: 112px 80px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 80px;
  background: var(--color-white);
}

.studies .title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.studies .title p {
  color: var(--color-text-dark);

  /* Text/Regular/Semi Bold */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  display: flex;
  width: 560px;
  flex-direction: column;
}

.studies .title h2 {
  display: flex;
  width: 560px;
  flex-direction: column;
  color: var(--text-dark, #403d39);

  /* Heading/H2 */
  font-family: Roboto;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

.studies .content {
  display: flex;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
}

.studies .content .column {
  display: flex;
  padding: 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 48px;
  flex: 1 0 0;
  border-radius: 12px;
  border: 1px solid var(--color-border-light);
  background: var(--color-white);

  /* large */
  box-shadow: 0px 8px 8px -4px rgba(0, 0, 0, 0.03),
    0px 20px 24px -4px rgba(0, 0, 0, 0.08);
}

.studies .content .column .text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}

.studies .content .column .text p {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  color: var(--color-text-dark);

  /* Text/Medium/Normal */
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.studies .content .column .avtar {
  display: flex;
  align-items: center;
  gap: 20px;
}

.studies .content .column .avtar .img1 {
  width: 56px;
  height: 56px;
  border-radius: 56px;
  /* background: url(../images/Placeholder/Avatar\ Image.png), lightgray 50% / cover no-repeat; */
}
.studies .content .column .avtar .img2 {
  width: 56px;
  height: 56px;
  border-radius: 56px;
  /* background: url(../images/Placeholder/Avatar\ Image1.png), lightgray 50% / cover no-repeat; */
}

.studies .content .column .avtar h6 {
  color: var(--color-text-dark);

  /* Text/Regular/Semi Bold */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}

.studies .content .column .avtar p {
  color: var(--color-text-dark);

  /* Text/Regular/Normal */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
/* testinomial end here */

.blue_button {
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}
.picture-mobile-about-sect {
  display: none;
}

@media screen and (max-width: 480px) {
  .picture-mobile-about-sect {
    display: block;
    width: 100%;
  }
  .picture-about-sec {
    display: none;
  }
  .content-head-sec-about {
    padding: 20px;
    align-self: stretch;
    width: 100%;
  }
  .about-sec-headi {
    font-size: 40px;
    width: 60%;
  }
  .about-layout {
    width: 100%;
    display: flex;
    padding: 61px 33px;
    align-items: center;
    gap: 28px;
    background: var(--text-white);
    flex-direction: column;
  }
  .abo-text {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
  .about-vision .about-frame .abo-text .right {
    display: none;
  }
  .about-vision {
    display: flex;
    width: 100%;
    padding: 87px 20px;
    align-items: flex-start;
    gap: 80px;
    background: linear-gradient(90deg, #0057b0 0%, #04aff0 100%);
  }
  .about-vision .about-frame {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }
  .ab-values .ab-row {
    display: flex;
    align-items: flex-start;
    gap: 48px;
    align-self: stretch;
    flex-direction: column;
  }
  .ab-values {
    display: flex;
    width: 100%;
    padding: 60px 25px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 80px;
    background: var(--color-white);
  }

  .ab-values .ab-row .ab-column {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    gap: 32px;
    flex: 1 0;
  }
  .ab-us-team {
    display: flex;
    width: 100%;
    padding: 39px 15px;
    flex-direction: column;
    align-items: flex-start;
    /* gap: 80px; */
    background: linear-gradient(90deg, #0057b0 0%, #04aff0 100%), #fff;
  }
  .transper-card-about {
    background: #afa7a785;
    height: 289px;
    width: 345px;
    margin-top: -104px;
    z-index: 99;
    background-color: #d0cacab3;
    -webkit-backdrop-filter: blur(12px);
    backdrop-filter: blur(12px);
    display: flex;
    padding: 24px 24px 32px 24px;
    flex-direction: column;
    /* align-items: flex-start; */
    gap: 19px;
  }

  .ab-us-team .ab-us-members .upper2 {
    display: flex;
    height: 100%;
    width: 100%;
    padding-top: 134px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    flex: 1 0;
  }

  .ab-us-team .ab-us-members {
    display: flex;
    width: 100%;
    align-items: flex-start;
    flex-direction: column;
    gap: 32px;
  }
}


.mySwiper {
  width: 100%;
  height: auto;
}

.mySwiper .about-slider {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  box-sizing: border-box;
}

@media (max-width: 480px) {
  .mySwiper {
    width: 100%;
  }

  .mySwiper .about-slider {
    width: 100%;
    height: 50px;
    box-sizing: border-box;
    /* margin-left: 150px;
    margin-right: 150px; */
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    /* right: 50%; */
  }

  .mySwiper .about-slider .transper-card-about {
    min-height: 400px;
    max-height: 400px;
    min-width: 300px;
    margin: 0 auto;
    box-sizing: border-box;

  }
}

/* Tablets and larger phones (up to 768px) */
@media (max-width: 768px) {
  .mySwiper {
    width: 100%;
    height: auto;
  }

  .mySwiper .about-slider {
    width: 100%;
    height: auto;
  }

  .picture-mobile-about-sect {
    display: block;
    width: 100%;
  }
  .picture-about-sec {
    display: none;
  }
}

@media (max-width: 1024px) {
  .mySwiper {
    width: 100%;
  }
  .about-Swiper{
    height: 600px;
  }

}


@media (min-width: 1025px) {
  .mySwiper {
    width: 100%;
  }
  .about-Swiper{
    height: 600px;
  }

}

/* auto responsive using reponsive hook */
@media (max-width : 766px){
  .about-layout{
    flex-direction: column;
  }
}
