.emp-messanger-card1 {
  background: #ffffff;
  height: auto;
  width: 100%;
  border: 1px solid #d7d7d7;
  box-shadow: 0px 4px 8px -2px rgba(0, 0, 0, 0.1),
    0px 2px 4px -2px rgba(0, 0, 0, 0.06);
  border-radius: 20px;
  padding: 20px;
  margin-bottom: 80px;
}
.emp-label-div {
  line-height: 2;
}
.emp-link {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}
@media screen and (max-width: 480px) {
  
  .emp-text-t {
    width: 100%;
    border: 1px solid #d7d7d7;
    box-shadow: 0px 4px 8px -2px rgba(0, 0, 0, 0.1),
      0px 2px 4px -2px rgba(0, 0, 0, 0.06);
    border-radius: 20px;
    padding: 20px;
    
  }
  .emp-label-div{
    line-height: 2;
  }
  .emp-link {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
  }
  @media screen and (max-width: 480px) {
    
    .emp-text-t{
      width: 100%;
      color: #403d39;
      font-weight: 400;
      font-size: 20px;
      align-items: center;
      padding: 8px 8px;
      border-radius: 8px;
      border: 1px solid var(--stroke-light, #D7D7D7);
      background: #FFF;
      
    
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
    }
  }
}