.psb-card-create-post {
  height: 347px !important;
}
.create-post-button {
  width: 237px;
  height: 39px;
  background: #04aff0;
  border-radius: 27px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #ffffff;
}
.create-post-button-div {
  margin: 20px 0px 24px;
}
.psb-profile-img-div {
  margin-top: -4px;
}
.psb-name {
  padding-top: 20px;
}

.ch-changepro-pic-text1 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  color: #403d39;
}
.ch-changepro-pic-text2 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #7b7b7b;
}

.profile-pic-change-modal {
  width: 488px;
  height: auto;
  border-radius: 12px;
  margin: 0 auto;
  display: flex;
  position: fixed;
  top: 0%;
  left: 35%;

}

