
.label-registration {
  font-weight: 400;
  font-size: 14px;
  color: var(--color-text-dark);
}

.select-box-country option:first-child {
  color:var(--color-text-extra-light);
}
/* Country.css */