/* StaticPageStyles.css */
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
/* Add other @import statements here */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;

  margin: 0;
}

body {
  font-family: "Roboto";

  font-style: normal;
}

h1 {
  font-size: 48px;
}

h2 {
  /* font-size: 40px; */

  font-family: Roboto;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

h3 {
  font-size: 32px;
}

h4 {
  /* font-size: 24px; */

  font-family: Roboto;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
}

h5 {
  /* font-size: 20px; */

  /* color: var(--color-text-dark, #403D39); */

  /* Heading/H5 */
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
}

h6 {
  /* font-size: 18px; */

  /* color: var(--color-text-dark); */

  /* Text/Large/Semi Bold */
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}

p {
  /* font-size: 16px; */

  margin-bottom: 0px;
}

button {
  cursor: pointer;

  background-color: white;
}

.error-symbol {
  color: red;
}

.Toastify__toast-theme--colored.Toastify__toast--info {
  background: #04aff0;
}

select {
  font-size: 14px;
}

/* Common styling for buttons */
.blue-button {
  border-radius: 33px;
  background: var(--color-brand-color);
  display: flex;
  padding: 8px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  color: var(--color-white);

  /* Text/Regular/Normal */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.blue-button:hover {
  background: var(--color-background-darkblue);
}

/* Background gradient color */
.background-gradient-color {
  background: linear-gradient(90deg, #0057b0 0%, #04aff0 100%), #eaeaea;
}
.hh-lang-convertion-flg-icon {
  width: 16px;
  height: 11px;
  flex-shrink: 0;
}
/* Header Section */
.static-top-bar-head {
  background: var(--white, #fff);
  /* medium */
  box-shadow: 0px 2px 4px -2px rgba(0, 0, 0, 0.1),
    0px 4px 8px -2px rgba(0, 0, 0, 0.1);
  display: flex;
  padding: 0px 80px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.static-top-bar-head {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: #fff;
}

.navigation {
  display: flex;
  align-items: center;
  gap: 27px;
}
nav ul {
  display: flex;
  align-items: center;
  gap: 24px;
  margin-top: 18px;
}
nav ul li a {
  color: rgba(0, 0, 0, 0.87);

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.5px;
}
/* .dropdown-head {
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 7px;
  border-radius: 4px;
} */
/* .option1 {
  background: url(/Figma-design-1/assets/images/icons/pseudo.png);
} */
.static-tog-btn {
  display: none;
}

/* Banner Section */
.full-banner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  background: var(--chip-blue, #eff5ff);
}
.banner-area-home {
  display: flex;
  /* height: 576px; */
  align-self: stretch;
  flex-direction: column;
  justify-content: center;

  gap: 24px;
}
.picture {
  width: 100%;
  /* position: absolute; */
}
.picture-mobile-home-b {
  display: none;
}
.picture-home-b {
  width: 100%;
  position: relative;
  top: 30px;
}

.content-head-section-st {
  display: flex;
  width: 786px;
  padding: 112px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  position: absolute;
}
.b-1 {
  color: var(--color-white);
  position: relative;
  top: 30px;
  font-family: Roboto;
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  filter: blur(0px);
}
.b-2 {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  color: var(--color-white);

  /* Text/Medium/Normal */
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  filter: blur(0px);
}
.below-banner {
  display: flex;
  padding: 8px 80px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.dots {
  display: flex;
  align-items: flex-start;
  gap: 8px;
}
.slider-btn {
  display: flex;
  align-items: flex-start;
  gap: 15px;
}
.arrow-btn {
  display: flex;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 50px;
  border: 1px solid var(--text-dark, #403d39);
  cursor: pointer;
}

/* Our Services Section */

.services-main {
  display: flex;
  padding: 40px 80px;
  flex-direction: column;
  align-items: flex-start;
  gap: 80px;
  align-self: stretch;
}
.services-head {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  color: var(--text-white, #fff);
}
.services-head h2 {
  display: flex;
  flex-direction: column;
  align-self: stretch;
}
.services-head p {
  display: flex;
  flex-direction: column;
  align-self: stretch;
}
.service-frame {
  display: flex;
  /* height: 216px; */
  align-items: center;
  gap: 35px;
  justify-content: center;
  align-self: stretch;
  flex-wrap: wrap;
}

.frame-ho {
  border-radius: 12px;
  /* background: linear-gradient(90deg, #93a5cf 0%, #e4efe9 100%); */
  align-items: center;  

  display: flex;
  /* height: 216px; */
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  /* flex: 1 0 0; */
}
.frame1-1 {
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.93);
  box-shadow: 0px 8px 8px -4px rgba(0, 0, 0, 0.03),
    0px 20px 24px -4px rgba(0, 0, 0, 0.08);
  display: flex;
  /* width: 373px; */
  height: 290px;
  padding: 20px;
  align-items: flex-start;
  gap: 8px;
  flex-shrink: 0;
  box-sizing: border-box;
}
.f1-2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}
.f1-22 {
  display: flex;
  /* width: 333px; */
  max-width: 333px;
  /* align-items: flex-start; */
  gap: 24px;
}
.hh-home-our-ser-tag {
  color: var(--text-dark, #403d39);
  font-family: Roboto;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 41.6px */
}
.f1-22 h4 {
  display: flex;
  align-items: center;
  gap: 16px;
  flex: 1 0 0;
  /* align-self: stretch; */
}

/* Carer section */

.career {
  display: flex;
  padding: 112px 80px;
  /* flex-direction: column; */
  /* align-items: flex-start; */
  gap: 80px;
  background: var(--color-white);
  align-self: stretch;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
}

.career .container {
  display: flex;
  /* align-items: flex-start; */
  gap: 80px;
  flex: 1 0 0;
  align-self: stretch;
}
.c-left {
  display: flex;
  /* width: 560px; */
  flex-direction: column;
  align-items: flex-start;
  gap: 80px;
  align-self: stretch;
}
.c-left .content2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}
.c-left .content2 .content22 {
  display: flex;
  /* width: 560px; */
  flex-direction: column;
  gap: 24px;
}
.hh-home-late-jobs-title {
  color: var(--text-dark, #403d39);
  font-family: Roboto;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 57.6px */
}
.hh-home-late-jobs-tag {
  color: var(--text-dark, #403d39);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
}
.hh-home-late-jobs-para {
  color: var(--text-dark, #403d39);
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 27px */
}
.hh-home-our-ser-para {
  color: var(--text-dark, #403d39);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
}
.hh-home-our-ser-para-div {
  max-width: 333px;
  height: 72px;
  width: 100%;
}
.lady-img {
  border-radius: 12px;
  /* background: url(<path-to-image>), lightgray 50% / cover no-repeat; */
}

.c-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  flex: 1 0 0;
  align-self: stretch;
}
.card {
  border-radius: 12px;
  border: 1px solid var(--stroke-light, #d7d7d7);
  background: #fff;

  /* large */
  box-shadow: 0px 8px 8px -4px rgba(0, 0, 0, 0.03),
    0px 20px 24px -4px rgba(0, 0, 0, 0.08);
  display: flex;
  padding: 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
}
.card .head {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}
.card .head .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.Dep {
  background: var(--color-light-grey);
  display: flex;
  padding: 4px 8px;
  align-items: flex-start;
  gap: 10px;
}
.head-home-pg {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}
.title-job-home {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.card .head label {
  background: var(--color-light-grey);
  display: flex;
  padding: 4px 8px;
  align-items: flex-start;
  gap: 10px;
}
.card .info {
  display: flex;
  align-items: flex-start;
  gap: 24px;
}
.infi-ic {
  color: #04aff0;
  width: 31px;
  height: 35px;
}
.joini-dot {
  color: #04aff0;
}
.joini-dot-white {
  color: white;
}

.info-home .info2-home {
  display: flex;
  align-items: center;
  gap: 12px;
  /* --color-brand-color: #04aff0; */
}
.info-home {
  display: flex;
  align-items: center;
  gap: 12px;
}

/* Choose us section */
.hh-home-wcu-title {
  color: var(--text-white, #fff);
  font-family: Roboto;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 57.6px */
}
.hh-home-wcu-para {
  color: var(--text-white, #fff);
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 27px */
}
.choose-us {
  display: flex;
  padding: 112px 80px;
  flex-direction: column;
  align-items: flex-start;
  gap: 80px;
  align-self: stretch;
  color: var(--color-white);
}
.choose-us .title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}
.choose-us .row {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 48px;
  align-self: stretch;
}
.choose-us .row .column {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  flex: 1 0 0;
}
.choose-us .row .column .col1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: stretch;
}
.hh-home-ch-us-sec-title {
  color: var(--text-white, #fff);
  text-align: center;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 33.6px */
}
.hh-home-ch-us-sec-para {
  color: var(--text-white, #fff);
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
}
/* About us Section */
.hh-home-about-tag {
  color: var(--text-dark, #403d39);
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
}
.hh-home-about-title {
  color: var(--text-dark, #403d39);
  font-family: Roboto;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 57.6px */
}
.hh-home-about-para {
  color: var(--text-dark, #403d39);
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 27px */
}
.about {
  display: flex;
  padding: 112px 80px;
  align-items: center;
  gap: 80px;
  align-self: stretch;
}
.about-1 {
  display: flex;
  /* width: 624px; */
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}
.about-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}
.wood-img {
  border-radius: 4px;
}
.wood-img-mobile {
  display: none;
}

/* Joining Section */
.hh-home-lat-joining-title {
  color: var(--text-white, #fff);
  font-family: Roboto;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 57.6px */
}
.hh-home-lat-joining-para {
  color: var(--text-white, #fff);
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 27px */
}
.joining {
  display: flex;
  padding: 112px 80px;
  flex-direction: column;
  align-items: flex-start;
  gap: 80px;
  align-self: stretch;
  color: var(--color-white);
}
.joining-head {
  display: flex;
  /* width: 768px; */
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}
.all-blocks-crd {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  max-width: 100%;
}
.swiper-slide {
  width: 100%; /* Adjust the card width as needed */
  margin-right: 10px; /* Adjust the margin as needed */
}
.block-card {
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 8px 8px -4px rgba(0, 0, 0, 0.03),
    0px 20px 24px -4px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  overflow: hidden; /* Hide overflowing content */
  margin-bottom: 70px;
  height: 283px;
  /* width: 400px; */
  width: auto;
  padding: 20px;

  align-items: flex-start;
  gap: 23px;
}

.b-img {
  /* Ensure the image takes up 100% width of the container */
  height: auto; /* Maintain aspect ratio */
  border-radius: 71px;
  width: 80px;
  height: 80px;
}

.block-card-content {
  /* padding: 0px 7px; */
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  color: var(--color-text-dark);
  overflow: auto; /* Add scrollbars if content overflows */
  max-height: 100%;
  max-width: 346px;
  width: 100%;
  box-sizing: border-box;
  word-break: break-all;
  overflow: hidden;
}

.joining-dot-line {
  display: flex;
  width: 100%;
  /* height: 48px; */
  justify-content: center;
  align-items: center;
  gap: 8px;
}

/* Customer Testemoial section */
.testimo-star {
  color: #ffd600;
  width: 20px;
  height: 20px;
}

.testimonial {
  display: flex;
  /* width: 1444px;
height: 567px; */
  padding: 112px 80px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 80px;
  flex-shrink: 0;
}
.t-head {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}
.boxes-with-dots {
  display: flex;
  /* width: 1250px; */
  flex-direction: column;
  /* align-items: flex-start; */
  gap: 32px;
  align-self: stretch;
}
.boxes {
  display: flex;
  align-items: flex-start;
  gap: 32px;
}
.hh-home-cu-testi-details {
  color: var(--text-dark, #403d39);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
}
.hh-home-cu-testi-name {
  color: var(--text-dark, #403d39);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
}
.hh-home-cu-testi-img {
  width: 56px;
  height: 56px;
}
.hh-home-cu-testi-heading {
  color: var(--text-dark, #403d39);
  font-family: Roboto;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 57.6px */
}
.hh-home-cu-testi-tagline {
  color: var(--text-dark, #403d39);
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 27px */
}
.hh-home-cu-testi-review {
  color: var(--text-dark, #403d39);
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 27px */
  max-width: 536px;
}
.box {
  display: flex;
  /* width: 400px; */
  padding: 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 12px;
  background: #f9f9fa;
  box-shadow: 0px 2px 4px -2px rgba(0, 0, 0, 0.1),
    0px 4px 8px -2px rgba(0, 0, 0, 0.1);
  /* height: 267px; */
  height: auto;
  margin-bottom: 80px;
}
.star {
  display: flex;
  align-items: flex-start;
  gap: 4px;
}
.box-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}
.box-c-1 {
  display: flex;
  align-items: center;
  gap: 20px;
}
.box-c-2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.testimonial-below .slider-btn {
  display: none;
}

/* Footer */
.link-color-home {
  color: white;
}

.static-footer-container {
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0.2) 100%
    ),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
    linear-gradient(90deg, #0057b0 0%, #04aff0 100%);
  color: var(--color-white);
  display: flex;
  /* padding: 32px; */
  padding: 80px;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  align-self: stretch;
}
.footer-box {
  display: flex;
  padding: 48px;
  align-items: flex-start;
  gap: 64px;
  align-self: stretch;
  border: 1px solid #fff;
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0.2) 100%
    ),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
    linear-gradient(90deg, #0057b0 0%, #04aff0 100%);
}

.icon-white {
  color: white;
  width: 24px;
  height: 24px;
}

.social-links {
  margin-left: -29px;
}
a,
ul {
  text-decoration: none;

  list-style: none;
}

.footer-col-1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  flex: 1 0 0;
}
.footer-col-11 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}
.social-links ul {
  display: flex;
  align-items: flex-start;
  gap: 12px;
}
.footer-col-2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  align-self: stretch;
}
.footer-grid {
  display: flex;
  width: 560px;
  align-items: flex-start;
  gap: 24px;
}
.footer-grid .line1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  flex: 1 0 0;
  align-self: stretch;
}
.footer-lang {
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 7px;
  border-radius: 4px;
  border: 1px solid var(--white, #fff);
}
.ph-no-static-page {
  text-decoration-line: underline;
}
.emaill-static-page {
  text-decoration-line: underline;
  color: white;
}
.footer-credits {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}
.footer-credits .footer-credits-link {
  display: flex;
  align-items: flex-start;
  gap: 24px;

  text-decoration-line: underline;
}

@media screen and (max-width: 480px) {
  h4 {
    font-size: 24px;
  }
  p {
    line-height: 150%;
  }

  .content-head-section-st {
    padding: 50px;
    align-self: stretch;
    width: 100%;
  }
  .block-card {
    border-radius: 12px;
    background: #fff;
    box-shadow: 0px 8px 8px -4px rgba(0, 0, 0, 0.03),
      0px 20px 24px -4px rgba(0, 0, 0, 0.08);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 283px;
    width: auto;
    padding: 20px;
    align-items: flex-start;
    gap: 23px;
  }

  header {
    padding: 8px 20px;
  }
  .static-tog-btn {
    display: block;
  }
  .navigation {
    display: none;
  }

  /* Banner Section */

  .picture-home-b {
    display: none;
  }
  .picture-mobile-home-b {
    display: block;
    width: 100%;
    /* position: absolute; */
  }
  .content {
    padding: 20px;
    align-self: stretch;
    width: 100%;
  }
  .b-1 {
    font-size: 40px;
    width: 60%;
  }

  /* Our Services Section */

  .frame1-1 {
    height: auto;
  }
  .services-main {
    padding: 40px 20px;
    gap: 76px;
  }
  .service-frame {
    flex-direction: column;
    gap: 20px;
  }
  .f1-2 {
    /* flex-direction: row; */
    align-items: center;
    gap: 16px;
  }
  .f1-22 {
    flex-direction: column;
    gap: 5px;
    align-items: center;
  }

  /* Carer section */

  .career {
    flex-direction: column;
    padding: 40px 10px;
    align-self: stretch;
  }
  .career .container {
    flex-direction: column;
    gap: 20px;
  }
  .c-left {
    width: auto;
  }
  .lady-img {
    display: none;
  }

  .card {
    align-items: stretch;
  }
  .card .head p {
    font-size: 14px;
  }
  .card .info {
    justify-content: space-between;
  }

  /* Choose us section */

  .choose-us {
    padding: 40px 20px;
  }
  .choose-us .row {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  /* About us Section */

  .about {
    padding: 40px 20px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 48px;
  }
  .wood-img {
    display: none;
  }
  .wood-img-mobile {
    display: block;
    border-radius: 4px;
    align-self: stretch;
  }

  /* Joining Section */

  .joining {
    padding: 40px 20px;
  }
  .all-blocks {
    flex-direction: column;
    gap: 40px;
  }
  .joining-dot-line {
    display: none;
  }

  /* Customer Testemoial section */
  .testimonial {
    padding: 40px 20px;
    gap: 20px;
    align-self: stretch;
  }
  .testimonial .joining-dot-line {
    display: block;
  }
  .boxes {
    overflow: scroll;
    flex-direction: column;
    flex-wrap: wrap;
  }
  .testimonial-below {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
  }
  .testimonial-below .slider-btn {
    display: flex;
  }

  /* Footer */

  footer {
    padding: 40px 20px;
    gap: 20px;
  }
  .footer-box {
    flex-direction: column;
  }
  .footer-col-2 {
    align-items: flex-start;
    gap: 20px;
  }
  .footer-grid {
    width: auto;
    flex-direction: column;
    align-self: stretch;
  }
  .footer-credits {
    flex-direction: column;
    gap: 20px;
    font-size: 14px;
  }
  .foot-static-pg {
    padding: 40px 20px;
    gap: 20px;
  }
  .static-footer-container {
    padding: 30px;
  }
}

@media (max-width: 576px) {
  footer {
    padding: 40px 20px;
    gap: 20px;
  }
  .footer-box {
    flex-direction: column;
  }
  .footer-col-2 {
    align-items: flex-start;
    gap: 20px;
  }
  .footer-grid {
    width: auto;
    flex-direction: column;
    align-self: stretch;
  }
  .about img{
    width: 100%;
  }
  .career{
    flex-direction: column;
  }
}

@media (min-width: 480px) and (max-width: 770px) {
  .content-head-section-st{
    width: auto;
    padding: 30px;
  }
  .footer-box{
    align-self: center;
  }
}

@media (min-width: 768px) and (max-width: 860px) {
  .static-top-bar-head{
    padding: 0 26px;
  }
}

@media screen and (max-width: 800px) {
  .picture-home-b {
    display: none;
  }
  .picture-mobile-home-b {
    display: block;
    width: 100%;
  }
 
}

@media (max-width: 1300px) {
  .about{
    flex-direction: column;
  }
}

@media (max-width: 1289px) {
  .footer-grid {
    width: auto;
  }
}

/* css for auto responsive by hook */
.frame1-1.small-device,
.frame1-1.mobile{
  height: auto;
  width: auto;
}
.frame1-1.tablet,
.frame1-1.desktop{
  height: 292px;
  width: auto;
}

.hh-home-our-ser-para-div.small-device,
.hh-home-our-ser-para-div.mobile,
.hh-home-our-ser-para-div.tablet,
.hh-home-our-ser-para-div.desktop{
  height: auto;
}


