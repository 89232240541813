.mobile-topbar-container{
    width:100%;
    box-shadow: 0px 4px 8px -2px rgba(0, 0, 0, 0.1), 0px 2px 4px -2px rgba(0, 0, 0, 0.1);
    height:65px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap:60%;
    /* z-index: 1; */
}
.static-mobile-topbar-container{
    width: 100%;
    box-shadow: 0px 4px 8px -2px rgba(0, 0, 0, 0.1), 0px 2px 4px -2px rgba(0, 0, 0, 0.1);
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 60%;
  
}
.mtp-icon{
    width:50px;
    cursor: pointer;
}
@media screen and (max-width: 896px) {
    .css-honmcm-MuiGrid-root>.MuiGrid-item, .css-sayu3r > .MuiGrid-item{
        padding: 0px;
    }
    .css-honmcm-MuiGrid-root{
        margin-top: 40px;
    }
    .css-honmcm-MuiGrid-root>.MuiGrid-item {
        padding-top: 10px !important;
        margin-top: 20px;
    }
    .css-sayu3r > .MuiGrid-item{
        padding-top: 0px !important;
        margin-top: 30px;
    }

    
}