.cp-title-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cp-title {
  font-weight: 700;
  color: #403d39;
}
.cp-edit-btn {
  width: 99px;
  height: 48px;
  background: #04aff0;
  border: 1px solid #04aff0;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  color: white;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
}

.cp-profile-card {
  background: #ffffff;
  height: auto;
  width: 100%;
  border: 1px solid #d7d7d7;
  box-shadow: 0px 4px 8px -2px rgba(0, 0, 0, 0.1),
    0px 2px 4px -2px rgba(0, 0, 0, 0.06);
  border-radius: 20px;
  padding: 20px;
  margin-bottom: 20px;
}

.cp-hr-line {
  background: #eaecf0;
  height: 1px;
  opacity: 0.8;
}

.cp-label-div {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  gap: 32px;
}
.cp-label-div-part1 {
  max-width: 280px;
  width: 100%;
}
.cp-label {
  /* width: 40%; */
  color: #7b7b7b;
  font-weight: 400;
  font-size: 20px;
  width: 100%;
}
.cp-text-div {
  width: 100%;
  
}
.cp-text-about-div{
  width: 100%;
  max-width: 512px;
}
.cp-text {
  color: #403d39;
  font-weight: 400;
  font-size: 20px;
  width: 100%;
}
.cp-text-about-text{
  color: #403d39;
  font-weight: 400;
  font-size: 20px;
  width: 100%;
  word-break: break-all;
}
.hhloader-div , .hhloader-div-ah, .hhloader-div-home-page{
  /* padding: 20px; */
  align-items: center;
  display: flex;
  justify-content: center;
  /* margin: auto; */
  flex-direction: column;
  row-gap: 8px;
  /* height: 100%; */
  flex-grow: 1;
  margin: 35% auto;
}
.hhloader-div-home-page{
  margin: 29% auto ;
}
.hhloader-div-ah{
  margin: 20% auto;
}
.loading-text-in-loader, .Typewriter, .Typewriter__wrapper {
  color: var(--brand-color, #04aff0);
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 18px */
}
.profileeditinput-proedit-name {
  display: flex;
  max-width: 512px;
  width: 100%;
  column-gap: 24px;
  flex-direction: row;
}

.cp-form-label-input-proedit,
.cp-form-label-input-proedit-name,
.cp-form-label-input-proedit-texteditor-div {
  display: flex;
  gap: 32px;
  align-items: center;
}
.cp-form-label-input-proedit-texteditor-div {
  align-items: normal;
}
.cp-form-label-div-proedit {
  max-width: 280px;
  width: 100%;
}
.cp-form-label-div-proedit-cu-req{
  max-width: 280px;
  width: 100%;
}
.cp-form-label-div-proedit-emp{
  max-width: 280px;
  width: 100%;
}
.cp-form-label-proedit {
  color: #7b7b7b;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  width: 100%; /* Set the label width to 100% */
}
.profileeditinput-proedit {
  width: 100%; 
  max-width: 512px;
 
}
.profileeditinput-proedit-cu-req{
  width: 100%; 
  max-width: 512px;
}
.emp-pro-edit-value{
  width: 100%; 
  max-width: 512px;
}
.profileeditinput-proedit-multi-select{
  width: 100%; 
  max-width: 512px;
  /* overflow: hidden; */
}
.profileeditinput-proedit-text-editor {
  width: 100%; 
  max-width: 447px;
}
.select-box-country {
  height: 50px;
  
  border: 1px solid #d7d7d7;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  width: 100%;
  padding: 10px;
  
}

/**Add the responsive css for the profile tab***/
@media only screen and (max-width: 896px) {
  .cp-label-div {
    flex-direction: column;
    /* margin-bottom: 10px; */
    gap: 8px;
    align-items: flex-start;
  }
  .cp-text {
    width: 100%;
    word-wrap: break-word;
  }
  .cp-profile-card {
    margin-bottom: 100px;
    padding: 24px 16px;
  }
  .cp-text-div {
    /* max-width: 450px; */
    width: 100%;
  }
  .cp-form-label-input-proedit {
    flex-direction: column;
    gap: 18px;
    align-items: flex-start;
  }
  .cp-form-label-input-proedit-name {
    gap: 8px;
    flex-direction: column;
    align-items: flex-start;
  }
  .profileeditinput-proedit-name {
    column-gap: 16px;
  }
  .cp-form-label-input-proedit-texteditor-div {
    flex-direction: column;
    gap: 8px;
  }
  .hhloader-div-home-page{
    margin: 70% auto ;
  }
}
@media screen and (max-width: 1536px) and (min-width: 1280px) {
  .profileeditinput-proedit-text-editor {
    max-width: 512px;
  }
}
