.aboutus-card-img{
    display: flex;

     height: 100%;
    width: 100%;

flex-direction: column;
justify-content: flex-end;
align-items: center;
flex: 1 0 0;
}
.card-per-name{
    color: var(--white, #FFF);
    /* Heading/Bold/H4 */
    font-family: Roboto;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 41.6px */
}
.card-per-postion{
    color: var(--white, #FFF);

/* Text/Medium/Semi Bold */
font-family: Roboto;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 27px */
}
.card-per-icon{
    color: var(--white, #FFF);
    display: flex;
align-items: center;
gap: 20px;
align-self: stretch;
}

.transper-card-about{
    background: #afa7a785;
    height: 100%;
    width: 100%;
    margin-top: -104px;
    z-index: 99;
    
    background-color: #d0cacab3;

/* Background blur / lg */
backdrop-filter: blur(12px);
display: flex;
padding: 24px 24px 32px 24px;
flex-direction: column;
align-items: flex-start;
gap: 19px;
align-self: stretch;
}