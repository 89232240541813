.send-msg-box-cus-to-emp {
  height: 24px;
}
.ecc-no-chat-selected {
  display: flex;
  height: 926px;
  padding: 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  border-radius: 20px;
  border: 1px solid var(--Stroke-Light, #d7d7d7);
  background: var(--White, #fff);

  /* small */
  box-shadow: 0px 2px 4px -2px rgba(0, 0, 0, 0.06),
    0px 4px 8px -2px rgba(0, 0, 0, 0.1);
}
.ecc-no-chat-selected-img{
  margin-bottom: 24px;
}
.ecc-no-chat-selected-text{
    color: var(--text-light, #7b7b7b);
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}
/* these below css for customization of vertical scrollbar */
/* Customize the scrollbar track (the area where the scrollbar moves) */
::-webkit-scrollbar {
  width: 8px; /* Adjust the width of the scrollbar */
}

/* Customize the scrollbar thumb (the draggable part of the scrollbar) */
::-webkit-scrollbar-thumb {
  background: #04aff0; /* Set the background color of the thumb */
  border-radius: 8px; /* Round the edges of the thumb */
}

/* Customize the scrollbar track on hover */
::-webkit-scrollbar-thumb:hover {
  background: #89c4db; /* Change the color on hover */
}

/* Customize the scrollbar track when it's grabbed (clicked on) */
::-webkit-scrollbar-thumb:active {
  background: #04aff0; /* Change the color when clicked */
}

/* Customize the scrollbar track (the area where the scrollbar moves) */
::-webkit-scrollbar-track {
  background: #f1f1f1; /* Set the background color of the track */
}

/* Customize the scrollbar corner (where horizontal and vertical scrollbars meet) */
::-webkit-scrollbar-corner {
  background: #f1f1f1; /* Set the background color of the corner */
}

/* Hide the scrollbar buttons (arrows at the ends of the scrollbar) */
::-webkit-scrollbar-button {
  display: none;
}

@media screen and (max-width:896px) {
 .ecc-no-chat-selected{
  margin-top: 30px;
  justify-content: normal;
 } 
}