@media screen and (max-width: 480px) {
    .emp-label-div {
      display: flex;
  
      flex-direction: column;
  
      white-space: nowrap;
    }
   
    .qualifi-emp-main {
        margin-top: 124px;
        margin-bottom: -8px;
    }
  
    .empeduc-edit-container{
        margin-bottom: 80px;
    }
  }
  