.no-noti-cont{
  font-family: Roboto;
font-size: 30px;
font-style: normal;
font-weight: 400;
}

.emp-noti-name{
    color: var(--text-dark, #403D39);
    margin-right: 10px;

/* Heading/Bold/H6 */
font-family: Roboto;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 140%; /* 28px */

}
.emp-noti-time{
    color: var(--text-light, #7B7B7B);

/* Text/Regular/Normal */
font-family: Roboto;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 24px */
}
.emp-noti-reply{
    color: var(--brand-color, #04AFF0);
    /* Text/Regular/Normal */
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    margin-right: 5px;
    cursor: pointer;
}
.emp-noti-sent-msg{
    color: var(--brand-color, #04AFF0);
/* Text/Medium/Normal */
font-family: Roboto;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 27px */
/* cursor: pointer; */
}
.emp-noti-para{
    color: var(--text-dark, #403D39);
    /* Text/Medium/Normal */
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 27px */
}
.emp-noti-profile-img {
    display: flex;
    width: 64px;
    height: 67px;
}
.main-notific-chat-container{
   display: flex;
   flex-direction: row;
   width: 100%
}
.main-info-header-container{
    display: flex;
align-items: center;
gap: 32px;
align-self: stretch;
}
.info-middle-container{
    display: flex;
padding: 0px 14px;
align-items: center;
gap: 8px;
align-self: stretch;
}
.info-last-container{
    display: flex;
padding: 8px 14px;
align-items: center;
gap: 8px;
align-self: stretch;
}
.info-noti-cont{
    display: flex;
    padding: 24px;
    flex-direction: column;
    gap: 12px;
    align-self: stretch;
    width: 100%;
}
.main-info-header-container {
    /* Your existing styles for .main-info-header-container */
    display: flex;
    justify-content: space-between; /* Pushes elements to opposite ends */
    align-items: center; /* Centers vertically */
  }
  
  .left-info {
    display: flex;
    align-items: center;
  }
  
 
  
  
  .emp-noti-reply-container {
    display: flex;
    align-items: center;
  }
  .noti-profile-pic-div{
    margin-top: 32px;
  }
  .noti-customer-profile-img{
    width: 64px;
    height: 64px;
    border-radius: 38px;
  }
  .noti-active-status-icon-div{
    width: 15px;
    height: 15px;
    bottom: 0px;
    right: 0px;
    border-radius: 50%;
    margin-left: 48px;
    margin-top: -12px;
    position: relative; /* Set position to absolute */
    z-index: 1; /* z-index now works */
  }

  .noti-img{
  
        padding-top: 2%;
        margin-left: 2%;
    
  }
  .emp-noti-reply-container-mobile{
    display: none;
  }

  @media screen and (max-width: 480px){
  .main-info-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    margin-top: 10px;

  }
  .left-info {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    margin-right: 50px;
  }
  .main-notific-chat-container {
    display: flex;
  
  }
  .info-noti-cont {
padding: 0px;
  }
  .info-middle-container {
    margin-left: -91px;
    margin-top: -30px;
  }
  .info-last-container{
    margin-left: -92px;
  }
  .emp-noti-reply {
    display: none;
  }
  .emp-noti-reply-container-mobile{
    display: none;
  }
  .emp-noti-reply-container-mobile {
    display: flex;
    align-items: center;
  }
  .emp-noti-reply-mobile{
    color: var(--brand-color, #04AFF0);
    /* Text/Regular/Normal */
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    margin-right: 5px;
    cursor: pointer;
    margin-left: 53px;
  }
  .noti-profile-pic-div {
    margin-top: 0px;
}
.emp-noti-time{
  color: var(--text-light, #7B7B7B);
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-right: 68px;
}

.emp-noti-name{
  color: var(--text-dark, #403D39);
  margin-right: 10px;

/* Heading/Bold/H6 */
font-family: Roboto;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 140%; /* 28px */
margin-right: -15px;
}

}
  