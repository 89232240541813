@media screen and (max-width: 480px) {
    .emp-label-div {
      display: flex;
  
      flex-direction: column;
  
      white-space: nowrap;
    }
    .container-emp-messanger-main {
      margin-top: 0px}
  }
  