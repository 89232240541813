.ch-post-text-div {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;
  /* justify-content: center; */
  margin: 24px 0px;
}
.ch-post-text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #7b7b7b;
}
.ch-post-hr-line {
  background-color: #d7d7d7;
  border: none;
  margin: 0 auto;
  /* align-self: stretch; */
  width: 930px;
}
.ch-post-req-button-mob-view-div {
  display: none;
}
.ch-post-req-helper-button-mob-view {
  border-radius: 12px;
  border: 1px solid var(--Brand-Color, #04aff0);
  background: var(--Brand-Color, #04aff0);

  /* xsmall */
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06),
    0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  padding: 10px 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  width: 100%;
  height: auto;
  max-width: 360px;
  margin: 16px auto;
  color: var(--White, #fff);

  /* Heading/Regualr/H6 */
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
@media screen and (max-width: 896px) {
  .ch-post-text-div {
    margin: 16px 12px;
  }
  .ch-post-hr-line {
    max-width: 310px;
  }
  .ch-post-req-button-mob-view-div {
    display: block;
  }
}
