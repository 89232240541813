.modal-b-container {
  width: 100%;
  max-width: 488px;
  padding: 20px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: #e4e7ec 1px solid;
  height: 450px;
  /* height: 237px; */
  background-color: #ffffff;
}

.modal-content-cp {
  max-width: 500px;
  width: 100%;
  max-height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  /* top:17vh; */
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ch-changepro-pic {
  margin: 12px;
  width: 24px;
  height: 24px;
}
/* .ch-changepro-pic-text {
    width: 90%;
    margin-top: 6px;
    margin-left: 15px;
  } */
.ch-changepro-pic-text1 {
  font-size: 18px;
  max-width: 358px;
  margin: 0px;
}
.ch-changepro-pic-text {
  margin: 0px;
}

.close-button-cp {
  width: 24px;
  height: 24px;
  margin-top: -21px;
  cursor: pointer;
}
.line-1-modal {
  width: 200px;
  height: 200px;
  border-top: 2px solid black;
}
/*  
  .ch-profilechange-image-dropzone {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
  } */
.text-dropzone-0 {
  font-size: 15px;
  text-align: center;
}
.text-dropzone-1 {
  text-decoration: none;
  color: var(--color-brand-color);
  font-size: 15px;
}
.text-dropzone-2 {
  font-size: 13px;
  text-align: center;
}
.modal-footer-container {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 10px;
  flex-direction: row;
  background-color: #ffffff;
  height: auto;
}

.image-preview-container-main {
  max-width: 442px;
  width: 100%;
  max-height: 310px;
  border: 1.5px dashed var(--color-brand-color);
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.image-previews {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.close-b-ip {
  position: absolute;
  top: 7px;
  right: 7px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.custom-modal-title-profile-pic-upload {
  width: 100%;
  max-width: 280px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  color: #7b7b7b;
  background-color: #ffffff;
}
@media screen and (max-width: 896px) {
  .modal-content-cp {
    width: 100%;
    max-width: 280px;
    max-height: 280px;
  }
  .ch-changepro-pic-text1 {
    max-width: 258px;
    font-size: 16px;
  }
  .image-previews {
    max-width: 250px;
  }
  .modal-b-container {
    max-width: 250px;
  }
  .image-preview-container-main {
    max-width: 250px;
    width: 100%;
    max-height: 250px;
  }
  .image-preview{
    max-width: 250px;
    width: 100%;
    max-height: 250px;
  }
}
