.ch-create-post-button-box {
  width: 100%;
  height: 84px;
  border: 1px solid #d7d7d7;
  box-shadow: 0px 4px 8px -2px rgba(0, 0, 0, 0.1),
    0px 2px 4px -2px rgba(0, 0, 0, 0.06);
  border-radius: 20px;
  display: flex;
  column-gap: 24px;
  align-items: center;
  justify-content: center;
  background: #fff;
}
.ch-create-post-button {
  width: 100%;
  height: 48px;
  border: 1px solid #d7d7d7;
  border-radius: 12px;
  text-align: left;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #7b7b7b;
  padding-left: 12px;
  margin-right: 16px;
}
.ch-profile-img {
  width: 52px;
  height: 52px;
  border-radius: 48px;
  margin-left: 16px;
}
.custom-modal-title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  color: #7b7b7b;
}
.modal-dialog {
  max-width: 700px;
}
.custom-modal-body {
  overflow-y: scroll;
  max-height: 493px;
}

.custom-create-post-modal {
  max-height: auto;

  border-radius: 12px;

  overflow: hidden;
}

.ch-cp-profile-img {
  width: 50px;
  height: 50px;
  background: linear-gradient(
    181.28deg,
    #95f2ff 13.42%,
    rgba(173, 244, 148, 0.16) 63.62%
  );
  border-radius: 32.7778px;
}
.ch-cp-popup-drodown-div {
  padding: 0px 24px 24px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 24px;
  white-space: nowrap;
}
.ch-cp-modal-popup-text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  color: #403d39;
  margin-bottom: 24px;
}
.ch-cp-popup-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* column-gap: 450px; */
  width: 100%;
}
.ch-cp-popup-vector1,
.ch-cp-popup-vector {
  margin-right: 23px;
}
.ch-cp-popup-vector1 {
  width: 35px;
  height: 35px;
}
.custom-modal-footer {
  justify-content: center;
}
.ch-cp-job-desc-textarea {
  padding: 10px 14px;
  background: #ffffff;
  border: 1px solid #d7d7d7;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  width: 652px;
  height: 152px;
}

.ch-cp-text-area-tagline {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #7b7b7b;
}
.ch-cp-image-dropzone,
.ch-profilechange-image-dropzone,
.ch-cp-camera-dropzone,
.ch-cp-camera-image-upload-dropzone {
  width: 652px;
  min-height: 152px;
  height: auto;
  padding: 10px;
  background: #ffffff;
  border: 1px solid #d7d7d7;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  align-items: center;
  display: flex;
  justify-content: center;
  /* margin-left: 10px; */
}
.ch-cp-camera-image-upload-dropzone {
  padding: 0px;
  margin: 0px;
  width: 100%;
}
.ch-profilechange-image-dropzone {
  /* width: 440px; */
  max-width: 400px;
  width: 100%;
  border: none;
  max-height: 100px;
  margin-top: 0px;
  height: auto;
  min-height: 50px;
  padding: 0px;
  box-shadow: none;
}
.ch-cp-camera-dropzone {
  display: flex;
  flex-direction: row;
  column-gap: 50px;
}
.ch-cp-image-dropzone-text {
  margin: auto 0px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  color: #04aff0;
  text-align: center;
  cursor: pointer;
}
.ch-cp-drop-zone-button {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #04aff0;
  width: 127px;
  height: 43px;
  border: 1px solid #04aff0;
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.1))
    drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.06));
  border-radius: 35px;
  margin-left: 24px;
}
.modal-body.custom-camera-modal-body {
  width: 700px;
  height: auto;
  padding: 0px;
  overflow-y: auto;
}
.ch-cp-captured-image {
  /* max-width: 600px; */
  max-width: 100%;
  /* max-height: 600px; */
  height: auto;
}
.ch-cp-preview-image-upload-by-file {
  max-width: 600px;
  max-height: 600px;
}
.ch-cp-remove-uploaded-file-icon {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  align-items: end;
}

.ch-cp-description-error {
  color: red;
  text-align: right;
}
@media screen and (max-width: 896px) {
  .ch-profile-img {
    width: 40px;
    height: 40px;
    margin-left: 0px;
  }
  .ch-create-post-button {
    min-width: 220px;
    height: 40px;
    border-radius: 39px;
    border: 1px solid #d7d7d7;
    padding: 8px 12px;
    margin: 0px;
  }
  .custom-modal-body {
    max-height: 450px;
    padding: 24px 12px;
  }
  .ch-cp-popup-drodown-div {
    padding: 0px;
    column-gap: 10px;
    margin-left: 0px;
  }
  .ch-cp-job-desc-textarea {
    width: 250px;
    height: 122px;
  }
  .ch-cp-modal-popup-text {
    font-size: 15px;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .ch-cp-popup-vector1,
  .ch-cp-popup-vector {
    margin-right: 10px;
  }
  .modal-dialog {
    max-width: 320px;
  }
  .modal-body.custom-camera-modal-body {
    width: 300px;
  }
  .ch-cp-drop-zone-button {
    font-size: 18px;
    width: 80px;
    margin-left: 15px;
  }
  .ch-cp-captured-image {
    width: 250px;
  }
  .ch-cp-captured-image-container {
    max-width: 250px;
  }
  .ch-cp-image-dropzone {
    width: 250px;
    flex-direction: column;
  }
  .ch-cp-preview-image-upload-by-file {
    max-width: 225px;
    max-height: 225px;
  }
  .ch-profilechange-image-dropzone {
    /* width: 440px; */
    max-width: 200px;
    width: 100%;
    border: none;
    max-height: 100px;
    margin-top: 0px;
    height: auto;
    min-height: 50px;
    padding: 0px;
    box-shadow: none;
  }
}
.post-btn-create-post-popup {
  padding: 10px 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid var(--Brand-Color, #04aff0);
  background: var(--Brand-Color, #04aff0);
  background-color: #04aff0;
  /* xsmall */
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06),
    0px 1px 3px 0px rgba(0, 0, 0, 0.1);
    width: 105px;
}
