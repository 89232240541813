.mobile-c-navbar {
  width: 100%;
  height: 65px;
  border-top: 2px solid var(--color-border-light);
  z-index: 1;
  /* display: flex; */
  justify-content: center;
  align-items: center;
  /* gap: 15%; */
  position: fixed;
  bottom: 0px;
  background-color: white;
}
.mobile-navbar-icon {
  width: 38px;
  cursor: pointer;
}
