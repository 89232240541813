.emp-title-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.emp-title {
  font-weight: 700;
  color: #403d39;
  margin-top: 10px;
  
}
.emp-hr-line-prof{
  color: white;
}
.mesan-top{
  margin-top: 40px;
}
.emp-edit-btn {
  width: 99px;
  height: 48px;
  background: #04aff0;
  border: 1px solid #04aff0;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  color: white;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
}
.emp-edit-btn-cp-cancel-btn {
  width: 99px;
  height: 48px;
  background: var(--white, #fff);
  border: 1px solid var(--stroke-light, #d7d7d7);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06),
    0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  color: var(--text-dark, #403d39);
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
}
.emp-profile-card1 {
  background: #ffffff;
  height: auto;
  width: 100%;
  border: 1px solid #d7d7d7;
  box-shadow: 0px 4px 8px -2px rgba(0, 0, 0, 0.1),
    0px 2px 4px -2px rgba(0, 0, 0, 0.06);
  border-radius: 20px;
  padding: 20px;
}
.emp-profile-card2 {
  background: #ffffff;
  height: auto;
  width: 100%;
  border: 1px solid #d7d7d7;
  box-shadow: 0px 4px 8px -2px rgba(0, 0, 0, 0.1),
    0px 2px 4px -2px rgba(0, 0, 0, 0.06);
  border-radius: 20px;
  padding: 20px;
}
.emp-profile-card3 {
  background: #ffffff;
  height: auto;
  width: 100%;
  border: 1px solid #d7d7d7;
  box-shadow: 0px 4px 8px -2px rgba(0, 0, 0, 0.1),
    0px 2px 4px -2px rgba(0, 0, 0, 0.06);
  border-radius: 20px;
  padding: 20px;
}
.emp-profile-card4 {
  background: #ffffff;
  height: auto;
  width: 100%;
  border: 1px solid #d7d7d7;
  box-shadow: 0px 4px 8px -2px rgba(0, 0, 0, 0.1),
    0px 2px 4px -2px rgba(0, 0, 0, 0.06);
  border-radius: 20px;
  padding: 20px;
}

.emp-hr-line {
  background: #eaecf0;
  height: 1px;
  opacity: 0.8;
}

.emp-label-div-profile,
.emp-label-div-desktop {
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;

}
.emp-label {
  width: 36%;
  color: #7b7b7b;
  font-weight: 400;
  font-size: 20px;
}
.emp-text {
  width: 60%;
  color: #403d39;
  font-weight: 400;
  font-size: 20px;
}
.emp-label-field-div {
 
  display: flex;
  align-items: flex-start;
  gap: 8px;
  flex-wrap: wrap;
}
.emp-text-multi {
  display: flex;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background: var(--chip-blue, #eff5ff);
}
.emp-label-province {
  align-items: center;
  padding: 4px 12px;

  width: 59px;
  height: 29px;



  background: #eff5ff;
  border-radius: 16px;
  display: inline;
  margin-right: 10px;
}

.emp-position {
  align-items: center;
  padding: 4px 12px;
  width: 59px;
  height: 29px;


  background: #eff5ff;
  border-radius: 16px;
  display: inline;
  margin-right: 10px;
}

@media screen and (max-width: 480px) {
  
  .container-emp-profile-main {
    margin-bottom: 100px;
  }
  .emp-label-field-div {
    flex-direction: column;
  }

  .emp-label-div-profile {
    display: flex;
    flex-direction: row;
  }
  .emp-label {
    width: 100%;
    margin-bottom: 14px;
  }
  .emp-text-multi {
    margin-right: 60px;
  }
}
