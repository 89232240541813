/* CSS for AvailableHelpersSideBar component */

/* This is the main container for the sidebar filters */
.ahsb-filters-div {
  display: flex !important;
  flex-direction: column;
  row-gap: 24px;
  max-width: 277px;
  width: 100%;
  margin: 0px auto;
  color: var(--text-dark, #403d39);
  font-family: Roboto;
  font-style: normal;
}
.ahsb-terms-cond-div{
  max-width: 277px;
  width: 100%;
  margin: 0px auto;
}

/* Styling for the sort-by dropdown container */
.ahsb-sort-by-div {
  display: flex;
  align-items: center;
  column-gap: 16px;
  flex-grow: 1;
}

/* Styling for the sort-by dropdown button */
.ahsb-sb-select-btn {
  padding: 8px 12px;
  border: 1px solid var(--stroke-light, #d7d7d7);
  border-radius: 12px;
  flex-grow: 1;
  font-size: 16px;
  color: #333;
  cursor: pointer;
  outline: none;
  width: 100%;
  max-width: 277px;
  display: flex;
  align-items: center;
}
.ahsb-sb-select-btn-sortby {
  padding: 12px 12px;
  border: 1px solid var(--stroke-light, #d7d7d7);
  border-radius: 12px;
  flex-grow: 1;
  font-size: 16px;
  color: #333;
  cursor: pointer;
  outline: none;
  width: 100%;
  max-width: 277px;
  display: flex;
  align-items: center;
}
/* Styling for the options within the sort-by dropdown */
.ahsb-sb-option {
  color: var(--text-light, #7b7b7b);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  width: 100%;
  margin: 0px auto;
}

/* Styling for the "Personalize your search" section */
.ahsb-div {
  display: flex;
  max-width: 277px;
  width: 100%;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 24px;
  border-radius: 20px;
  border: 1px solid var(--stroke-light, #d7d7d7);
  background: var(--white, #fff);
  box-shadow: 0px 2px 4px -2px rgba(0, 0, 0, 0.06),
    0px 4px 8px -2px rgba(0, 0, 0, 0.1);
}

/* Styling for the heading in the "Personalize your search" section */
.ahsb-text-heading {
  font-size: 24px;
  font-weight: 700;
  line-height: 140%; /* 33.6px */
  margin: 0px;
}

/* Styling for the "Clear all" button in the "Personalize your search" section */
.ahsb-clear-all-button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

/* Styling for the filter labels */
.ahsb-position-filter-label-text,
.ahsb-work-schedule-filter-label-text,
.ahsb-country-filter-label-text,
.ahsb-state-filter-label-text,
.ahsb-city-filter-label-text,
.ahsb-salary-filter-label-text,
.ahsb-age-filter-label-text {
  font-size: 18px;
  font-weight: 600;
  line-height: 150%; /* 27px */
}

/* Styling for the filter label containers */
.ahsb-position-filter-label-div,
.ahsb-work-schedule-filter-label-div,
.ahsb-country-filter-label-div,
.ahsb-state-filter-label-div,
.ahsb-city-filter-label-div,
.ahsb-salary-filter-label-div,
.ahsb-age-filter-label-div {
  border-top: 1px solid var(--stroke-light, #d7d7d7);
  display: flex;
  padding: 20px 0px;
  align-items: center;
  align-self: stretch;
  width: 100%;
  max-width: 237px;
  margin: 0 auto;
}
.ahsb-salary-filter-label-div,
.ahsb-age-filter-label-div {
  border: none;
  padding: 0px 0px 20px;
}
/* Styling for the position filter container and dropdown */
.ahsb-position-filter-div,
.ahsb-work-schedule-filter-div {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.ahsb-state-filter-div,
.ahsb-city-filter-div,
.ahsb-salary-filter-div,
.ahsb-age-filter-div {
  max-width: 237px;
  width: 100%;
}
.ahsb-coutry-filter-div{
  max-width: 277px;
  width: 100%;
}

/* Styling for the position filter dropdown button */
.ahsb-position-filter-select {
  display: flex;
  padding: 12px;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}

/* Styling for the work schedule filter buttons */
.ahsb-work-schedule-filter-button-div {
  display: flex;
  padding: 8px 0px;
  align-items: center;
  column-gap: 12px;
  align-self: stretch;
}

/* Styling for the work schedule filter button container */
.ahsb-work-schedule-filter-buttons-div {
  display: flex;
  flex-direction: column;
}
.psb-terms-ah {
  max-width: 277px !important;
  width: 100%;
}
.psb-about-div-ah, .psb-about-div-ahsb {
  max-width: 277px !important;
  width: 100%;
}
.ahsb-work-schedule-radio {
  display: flex;
  position: relative;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  align-items: center;
}
/* Hide the browser's default checkbox */
.ahsb-work-schedule-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.ahsb-work-schedule-radio-label-text {
  margin-left: 30px;
}

/* Create a custom checkbox */
.checkmark-work-schedule {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  border-radius: 15px;
  border-radius: 100px;
  border: 1px solid var(--text-dark, #403d39);
  background: var(--white, #fff);
}
.ahsb-work-schedule-radio:hover input ~ .checkmark-work-schedule {
  background-color: #fff;
}

/* When the checkbox is checked, add a blue background */
.ahsb-work-schedule-radio input:checked ~ .checkmark-work-schedule {
  background-color: #04aff0;
}
.checkmark-work-schedule:after {
  content: "";
  position: absolute;
  display: none;
}
.ahsb-work-schedule-radio input:checked ~ .checkmark-work-schedule:after {
  display: block;
}
.ahsb-work-schedule-radio .checkmark-work-schedule:after {
  left: 6px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.ahsb-salary-range-select-div,
.ahsb-age-range-select-div {
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  width: 100%;
}
.ahsb-salary-range-select-min-div,
.ahsb-age-range-select-min-div {
  flex-grow: 1;
  width: 100%;
  display: flex;
}
.ahsb-apply-filter-button {
  width: 100%;
  max-width: 277px;
  background-color: #04aff0;
  color: #fff;
}
.ahsb-apply-filter-button-div {
  padding: 12px 15px;
  border-radius: 12px;
  background-color: #04aff0;
  display: flex;
  width: 100%;
  max-width: 277px;
  align-items: center;
  margin-bottom: 50px;
}
.ahsb-showing-noof-posts-text {
  color: var(--text-dark, #403d39);

  /* Text/Small/Normal */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
}
.workschedule-selected{
  color: #04aff0;
}
@media screen and (max-width: 896px) {
  .ahsb-div {
    padding: 10px;
    row-gap: 15px;
  }
  .ahlh-sort-large-screen-view {
    display: none;
  }
.ahsb-sb-select-btn-sortby {
  max-width: 180px;
  height: 50px;
}
}
